import classNames from 'classnames';
import React, { FC } from 'react';
import styles from "./Layout.module.scss";

export interface LayoutProps{
    children?: JSX.Element;
    className?: string;
}

export const Empty: FC<LayoutProps> = ({
    children,
    className,
}) => {
    return(
        <div className={classNames(
            styles.Layout__Empty,
            className && className
        )}>
            {children}
        </div>
    );
  }
  export default Empty;