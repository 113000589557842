import IAuthUser from "../interfaces/IAuthUser";

export const emptyUserState : IAuthUser = {
    "userId": "",
    "email": "",
    "username": "",
    "firstName": "",
    lastName: "",
    "surname": "",
    "roles": [],
    "agencyId": null,
    "agencyName": null,
    "isNewsletterSubscribed": false,
    "accountType": null,
    "enabled": true,
    "imageUrl": null,
    "iagency": null,
    "creditBudget": 0,
    "availableCredits": 0,
    "phone": null,
    "billingInfo": {
        subjectName: "",
        zipCode: '',
        vatNumber: '',
        street: '',
        registrationNumber: '',
        lastname: '',
        country: '',
        city: '',
        email: '',
        phone: '',
        firstName:''
    }
}