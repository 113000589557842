import React from 'react';
// import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import * as serviceWorker from './serviceWorker';
import { QueryClient, QueryClientProvider } from 'react-query';
// import App from './App';
import App from './App';
import { ErrorBoundaryCritical } from './layout/errorBoundary/ErrorBoundary';
import { RecoilRoot } from 'recoil';
import { ReactQueryDevtools } from 'react-query/devtools';
import * as FullStory from '@fullstory/browser';
import './i18n';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    },
  },
});

// import CostLocker from "./comp/CostLocker";
Sentry.init({
  dsn: 'https://c9d0c68a2e8d4089b482af547dac2b25@o482046.ingest.sentry.io/5555540',
  autoSessionTracking: true,
  environment: process.env.NODE_ENV,
  integrations: [new Integrations.BrowserTracing()],
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});

FullStory.init({ orgId: 'o-1D4RYK-na1' });

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
  <React.StrictMode>
    <ErrorBoundaryCritical>
      <RecoilRoot>
        <QueryClientProvider client={queryClient}>
          <App />
          <ReactQueryDevtools />
        </QueryClientProvider>
      </RecoilRoot>
    </ErrorBoundaryCritical>
  </React.StrictMode>
);

// ReactDOM.render(
// <React.StrictMode>
//   <ErrorBoundaryCritical>
//     <RecoilRoot>
//       <QueryClientProvider client={queryClient}>
//         <App />
//         <ReactQueryDevtools />
//       </QueryClientProvider>
//     </RecoilRoot>
//   </ErrorBoundaryCritical>
// </React.StrictMode>,
//   document.getElementById('root')
// );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
