import { FC, PropsWithChildren } from 'react';
import { createPortal } from 'react-dom';

type ReactPortalProps = PropsWithChildren<{
  wrapperId?: string;
}>;

const ReactPortal: FC<ReactPortalProps> = ({
  children,
  wrapperId = 'portal-root',
}) => {
  const target = document.getElementById(wrapperId);
  if (target) {
    return createPortal(children, target);
  } else {
    return null;
  }
};
export default ReactPortal;
