import { FC, ReactElement } from 'react';
import styles from './Tabs.module.scss';
import classNames from 'classnames';
import Button from '../button/Button';

interface TabsProps {
  list: (string | ReactElement)[];
  className?: string;
  active: number;
  setActive: (next: number) => void;
}

const Tabs: FC<TabsProps> = ({ list, className, active, setActive }) => {
  const random = (Math.random() * 100000).toPrecision(1);

  return (
    <div className={classNames(styles.Tabs, className && className)}>
      {list.map((single, i) => {
        return (
          <Button
            key={`Tab-${i}-${random}`}
            design="none"
            onClick={() => setActive(i)}
            className={classNames(
              styles.Tab,
              active === i && styles.Tab__active
            )}
          >
            {single}
          </Button>
        );
      })}
      <div
        className={styles.Tabs_line}
        style={{ gridColumn: `1 / span ${list.length}` }}
      ></div>
      <div
        className={styles.Tabs_line__active}
        style={{ gridColumn: `${active + 1 || 1} / span 1` }}
      />
    </div>
  );
};
export default Tabs;
