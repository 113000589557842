export enum IconType {
  analysis = 'analysis',
  analysisActive = 'analysis-active',
  arrow = 'arrow',
  arrowLong = 'arrow-long',
  bell = 'bell',
  bellActive = 'bell-active',
  buttonAccept = 'button-accept',
  calendar = 'calendar',
  camera = 'camera',
  chart = 'chart',
  chevron = 'chevron',
  copy = 'copy',
  cross = 'cross',
  expand = 'expand',
  filter = 'filter',
  home = 'home',
  homeActive = 'home-active',
  info = 'info',
  logo = 'logo',
  logoWide = 'logo-wide',
  logout = 'logout',
  logoutActive = 'logout-active',
  menuToggle = 'menu-toggle',
  message = 'message',
  messageActive = 'message-active',
  plus = 'plus',
  projects = 'projects',
  projectsActive = 'projects-active',
  settings = 'settings',
  settingsActive = 'settings-active',
  statusOk = 'status-ok',
  sum = 'sum',
  user = 'user',
  userActive = 'user-active',
  ok = 'ok',
  time = 'time',
  more = 'more',
  moreActive = 'more-active',
  google = 'google',
  edit = 'edit',
  editActive = 'edit-active',
  download = 'download',
  wallet = 'wallet',
}
