import { ProjectPlan } from '../../enums/ProjectPlan';
import { RequestMethod } from '../../enums/RequestMethod';
import { apiBaseUri, call, getApiUri } from '../../helpers/call/Calls';
import { IProject } from '../../interfaces/IProject';
import { IProjectKeyword } from '../../interfaces/IProjectKeyword';

export interface INewProject {
  name: string;
  domain: string;
  location: string;
  competitors: Array<string>;
  thumbImagePath?: string;
  googleToken?: string | null;
  collabimAuthKey?: string;
  projectPricePlan: ProjectPlan;
}

export const createProject = async (
  data: INewProject,
  token: string
): Promise<IProject> => {
  // const commandUri = Calls.getApiUri(Calls.apiBaseUri+"project");
  let options = {
    name: data.name,
    domain: data.domain,
    location: data.location,
    googleToken: data.googleToken,
    competitors: data.competitors,
    projectPricePlan: data.projectPricePlan,
    thumbImagePath: data.thumbImagePath,
  };
  if (typeof data.googleToken == 'string') {
    options.googleToken = data.googleToken;
  }
  // return await Calls.call("POST", commandUri, options);
  return await call(
    RequestMethod.POST,
    getApiUri(`${apiBaseUri()}project`),
    token,
    options
  );
};

export const importKeywordsManual = async (
  projectId: string,
  keywordList: Array<string>,
  token: string
): Promise<Array<IProjectKeyword>> => {
  return await call(
    RequestMethod.POST,
    getApiUri(`${apiBaseUri()}project/${projectId}/keywords/import/list`),
    token
  );
};
export const importKeywordsCollabim = async (
  projectId: string,
  apiKey: string,
  collabimProjectId: number,
  token: string
): Promise<Array<IProjectKeyword>> => {
  return await call(
    RequestMethod.POST,
    getApiUri(`${apiBaseUri()}project/${projectId}/keywords/import/collabim`),
    token,
    {
      collabimProjectId: collabimProjectId,
      collabimApiKey: apiKey,
    }
  );
  // const commandUri = Calls.getApiUri(`${Calls.apiBaseUri}project/${projectId}/keywords/import/collabim`);
  // return await Calls.call("POST", commandUri, {
  //     "collabimApiKey": apiKey,
  //     "collabimProjectId": collabimProjectId
  // })
};
export const importKeywordsFile = async (
  projectId: string,
  fileName: string,
  token: string
): Promise<Array<IProjectKeyword>> => {
  return await call(
    RequestMethod.POST,
    getApiUri(`${apiBaseUri()}project/${projectId}/keywords/import/file`),
    token,
    {
      name: fileName,
    }
  );
  // const commandUri = Calls.getApiUri(`${Calls.apiBaseUri}project/${projectId}/keywords/import/file`);
  // return await Calls.call("POST", commandUri, {name: fileName})
};
// export const importKeywordsFile = async (projectId:)
