import {atom, selector} from "recoil";
import IAuthToken from "../interfaces/IAuthToken";

export const authState = atom({
  key : "authState",
  default: {
      refreshToken: null,
      token: null,
      tokenType: null
  } as IAuthToken });

export const authValue = selector({
  key: "authStateGet",

  get: ({ get }) => ({
    token: get(authState).token || undefined,
    refreshToken: get(authState).refreshToken,
    isAuthenticated: get(authState).token !== null,
  }),
});