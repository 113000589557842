import classNames from 'classnames';
import React, { FC, PropsWithChildren, useEffect } from 'react';
import { IconType } from '../../enums/IconType';
import IconButton from '../button/IconButton';
import Heading from '../heading/Heading';
import ReactPortal from '../reactPortal/ReactPortal';
import styles from './Modal.module.scss';

type ModalProps = PropsWithChildren<{
  heading?: string;
  date?: Date;
  author?: string;
  content?: string;
  isDisabled?: boolean;
  notFullScreen?: boolean;
  dismiss: () => void;
  size?: 'normal' | 'small' | 'high';
  align?: 'center';
  meta?: string[];
  dismissOnClick?: boolean;
  className?: string;
}>;

const Modal: FC<ModalProps> = ({
  heading,
  date,
  author,
  content,
  isDisabled,
  notFullScreen,
  dismiss,
  children,
  size,
  meta,
  align,
  dismissOnClick = true,
  className,
}) => {
  // function handleClick(){
  //   if(dismissOnClick){
  //     dismiss()
  //   }
  // }

  const dateConst = new Date(date || '');

  useEffect(() => {
    const closeOnEscapeKey = (e: { key: string }) =>
      e.key === 'Escape' ? dismiss() : null;
    document.body.addEventListener('keydown', closeOnEscapeKey);
    return () => {
      document.body.removeEventListener('keydown', closeOnEscapeKey);
    };
  }, [dismiss]);

  return (
    <ReactPortal>
      <div
        onClick={() => dismiss()}
        className={classNames(
          styles.modal,
          notFullScreen !== true && styles.modal__full,
          size && styles[`modal__${size}`],
          align && styles[`modal__align${align}`],
          isDisabled && styles.modal__disabled,
          dismissOnClick && styles.modal__dismissable,

          className && className
        )}
      >
        <div
          className={styles.modalInner}
          onClick={(e) => {
            e.stopPropagation();
            dismissOnClick && dismiss();
          }}
        >
          <IconButton
            className={styles.close}
            icon={IconType.cross}
            onClick={dismiss}
            design="light"
            size={48}
          />
          <Heading type="h4" className={styles.heading}>
            {heading}
          </Heading>
          {(date || author || meta) && (
            <div className={styles.info}>
              {date && dateConst.toLocaleDateString()}
              {author && <span className={styles.author}>{author}</span>}
              {meta?.map((single) => {
                return <span>{single}</span>;
              })}
            </div>
          )}
          {content && (
            <div
              className={styles.content}
              dangerouslySetInnerHTML={{ __html: content }}
            />
          )}
          <div className={styles.content}>
            {children !== undefined ? children : ''}
          </div>
        </div>
      </div>
    </ReactPortal>
  );
};
export default Modal;
