import classNames from 'classnames';
import React, { FC } from 'react';
import { IconType } from '../../enums/IconType';
import Icon from '../icon/Icon';
import styles from "./Segment.module.scss";

interface SegmentProps{
    onClick?: ()=>void;

    design?: "normal" | "active" ;
    label: string;
    value?: string;

    type?: "button";
    className?: string;
}

const Segment: FC<SegmentProps> = ({
    onClick,
    design = "normal",
    label,
    value,
    type = 'button',
    className,
}) => {

    return (
        <button
            className={classNames(
                styles.Segment,
                styles[`Semgent__${design}`],
                
                className && className
            )}
            type={type}
            onClick={onClick}
        >
            <span className={styles.Segment_label}>
                {label}
            </span>
            {value && (
                <span className={styles.Segment_value}>
                    {value}
                </span>
            )}
            {/* {design === 'active' && <IconButton className={styles.Segment_cross} icon={IconType.cross} />} */}
            {design === 'active' && <div className={styles.Segment_cross}><Icon icon={IconType.cross} isWhite /></div>}
        </button>
    );
}
export default Segment;