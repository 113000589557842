import React, { FC, useCallback, useEffect, useState } from 'react';
// import Select from 'react-select';
import { useRecoilValue } from 'recoil';
import { IllustrationType } from '../../../enums/IllustrationType';
import { RequestMethod } from '../../../enums/RequestMethod';
import { apiBaseUri, call, getApiUri } from '../../../helpers/call/Calls';
import { authValue } from '../../../store/authState';
import { userValue } from '../../../store/userState';
import Button from '../../../component/button/Button';
import { ButtonLinkExternal } from '../../../component/button/ButtonLink';
import Illustration from '../../../component/illustration/Illustration';
import LoaderInfinity from '../../../component/loader/LoaderInfinity';
import Select from '../../../component/select/Select';
// const commandUri = "https://api.oncollabim.com/projects";

interface ICollabimData {
  id: number;
  name: string;
  webSiteUrl: string;
  active: number;
}
export interface ICollabimState {
  apiKey: string;
  projectId: number;
}
function instanceOfICollabimState(object: any): object is ICollabimState {
  return 'apiKey' in object && 'projectId' in object;
}

interface KeywordsCollabimProps {
  data: any;
  setData: (nextData: ICollabimState) => void;
}

const KeywordsCollabim: FC<KeywordsCollabimProps> = ({ data, setData }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [err, setErr] = useState<string>('');

  const [apiKey, setApiKey] = useState<string>('');

  const [projectsList, setProjectsList] = useState<Array<ICollabimData>>([]);
  const [selectedProject, setSelectedProject] = useState<number>();

  const { roles } = useRecoilValue(userValue);

  const { token } = useRecoilValue(authValue);

  useEffect(() => {
    if (data && instanceOfICollabimState(data)) {
      fetch(data.apiKey);
      setApiKey(data.apiKey);
      setSelectedProject(data.projectId);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const fetch = useCallback((apiKeyLocal: string) => {
    setIsLoading(true);
    call(
      RequestMethod.GET,
      getApiUri(`${apiBaseUri()}collabim/project`, {
        apiKey: apiKeyLocal,
      }),
      token
    )
      .then((resp: { projects: Array<ICollabimData> }) => {
        setProjectsList(resp.projects);
        setIsLoading(false);
        setErr('');
      })
      .catch((error) => {
        setErr('Načtení projektů se nezdařilo, zkontrolujte prosím API klíč.');
        setIsLoading(false);
      });
  }, []);

  function handleSelection(next: any) {
    const nextVal = parseInt(next?.value);
    setSelectedProject(nextVal);
    if (nextVal) {
      setData({
        apiKey: apiKey,
        projectId: nextVal,
      });
    }
  }
  let projectsRender: Array<any> = [];
  let selectedRender;
  projectsList.forEach((single) => {
    projectsRender.push(new Option(single.name, single.id.toString()));
    if (single.id === selectedProject) {
      selectedRender = new Option(single.name, single.id.toString());
    }
  });
  if (err !== '') {
    return (
      <div className="keywords-import-collabim keywords-import-collabim-error  text-center">
        <Illustration img={IllustrationType.NewProjectWrong} alt="wrong one" />
        <h6>
          Omlouváme se, ale zadaný <br />
          API klíč je neplatný.
        </h6>
        <p>
          Abychom ti to usnadnili, připravili jsme pro tebe návod, kde správný
          API klíč získat, mrkni se
          <ButtonLinkExternal to="https://keywordeater.cz" design="link">
             zde 
          </ButtonLinkExternal>
          a pak to zkus znovu.
        </p>
        <Button
          onClick={() => {
            setErr('');
            setProjectsList([]);
          }}
          design="primary"
        >
          Zkusit znovu
        </Button>
      </div>
    );
  }
  return (
    <div className="keywords-import-collabim text-center">
      <div className="input-group">
        <label htmlFor="apiKey">API klíč</label>
        <input
          type="text"
          name="apiKey"
          id="apiKey"
          value={apiKey}
          onChange={(evt) => setApiKey(evt.target.value)}
          placeholder="Zadej API klíč"
        />
      </div>
      {(projectsList.length > 0 || isLoading) && (
        <>
          <hr />
          <div
            className={`input-group gap-before-small ${
              projectsRender.length === 0 && 'disabled'
            }`}
          >
            <label htmlFor="apiKey">Projekt</label>
            <Select
              className={`search-select`}
              placeholder="Vyberte projekt"
              optionList={projectsRender}
              isDisabled={projectsRender.length === 0}
              isLoading={isLoading}
              isSearchable={true}
              onChange={handleSelection}
              optionSelected={selectedRender}
            />
          </div>
        </>
      )}
      {projectsList.length === 0 && (
        <Button onClick={() => fetch(apiKey)}>Načíst</Button>
      )}
      {projectsList.length > 0 && (
        <p className="change-api-key">
          Zadal jsi jiný API klíč, než jsi chtěl?
          <Button onClick={() => setProjectsList([])} design="link">
            Zadat nový API klíč
          </Button>
        </p>
      )}
      {err && <p className="text-center decrease-color">{err}</p>}
      {isLoading && <LoaderInfinity />}
    </div>
  );
};

export default KeywordsCollabim;
