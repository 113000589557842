import React, { useEffect, useState } from 'react';
import { Location, Outlet, useLocation } from 'react-router';
import { useRecoilState } from 'recoil';
import { RequestMethod } from '../../enums/RequestMethod';
import { apiBaseUri, call, getApiUri } from '../../helpers/call/Calls';
import IAuthToken from '../../interfaces/IAuthToken';
import { authState } from '../../store/authState';
import ReactPortal from '../../component/reactPortal/ReactPortal';

function RefreshTokenHandler() {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [auth, setAuth] = useRecoilState(authState);
  const location: Location = useLocation();

  // useEffect(() => {
  //     // if(localStorage?.keaterToken && (location.pathname === "/" || location.pathname === "/login")){
  //     //     doRefreshTokenHandler(localStorage?.keaterToken);
  //     // }
  // }, [location])

  useEffect(() => {
    const interval = setInterval(() => {
      doRefreshTokenHandler();
    }, 20 * 60 * 1000);

    return () => clearInterval(interval);
  }, [auth.token]);

  function doRefreshTokenHandler() {
    if (auth.token !== null) {
      setIsLoading(true);
      call(
        RequestMethod.POST,
        getApiUri(`${apiBaseUri()}refresh-token`),
        auth.token,
        {
          refreshToken: auth.refreshToken,
        }
      )
        .then((nextToken: IAuthToken) => {
          setAuth(nextToken);
          setIsLoading(false);
        })
        .catch((err) => {
          console.error('Error', err);
          setIsLoading(false);
        });
    }
  }

  return (
    <>
      <Outlet />
      <ReactPortal>
        <div className="token-handler">
          {/* {isLoading && <LoaderInfinity/>} */}
        </div>
      </ReactPortal>
    </>
  );
}

export default RefreshTokenHandler;
