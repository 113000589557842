import classNames from 'classnames';
import { FC, useState } from 'react';
import { ProjectPlan } from '../../../enums/ProjectPlan';
import Segment from '../../../component/button/Segment';
import Illustration from '../../../component/illustration/Illustration';
import styles from './NewProject.module.scss';
const planLimit = {
  [ProjectPlan.PRO]: 25,
  [ProjectPlan.ESSENTIALS]: 10,
  [ProjectPlan.STARTER]: 5,
  [ProjectPlan.CUSTOM]: '-',
};

interface NewProjectCompetitorsProps {
  domain: string;
  competitors: string[];
  updateCompetitors: (list: string[]) => void;
  plan: ProjectPlan;
}

const NewProjectCompetitors: FC<NewProjectCompetitorsProps> = ({
  domain,
  competitors = [],
  updateCompetitors,
  plan,
}) => {
  const [newCompetitor, setNewCompetitor] = useState('');

  //TODO: čeká na api, pak budeme načítat přímo v této kompoonentě
  // eslint-disable-next-line
  const [hintList, setHintList] = useState([
    {
      url: 'www.seznam.cz',
      img: 'https://d32-a.sdn.cz/d_32/c_static_QN_L/Tjwly/media/img/seznam-icons/favicon-192x192.png',
    },
    {
      url: 'www.ddworld.cz',
      img: 'https://d32-a.sdn.cz/d_32/c_static_QN_L/Tjwly/media/img/seznam-icons/favicon-192x192.png',
    },
    {
      url: 'www.diit.cz',
      img: 'https://d32-a.sdn.cz/d_32/c_static_QN_L/Tjwly/media/img/seznam-icons/favicon-192x192.png',
    },
    {
      url: 'www.keater.cz',
      img: 'https://d32-a.sdn.cz/d_32/c_static_QN_L/Tjwly/media/img/seznam-icons/favicon-192x192.png',
    },
  ]);

  function updateCompetitorsInner(evt: any) {
    if (newCompetitor && (evt.key === 'Enter' || evt.key === ',')) {
      const newItem =
        evt.key === 'Enter'
          ? newCompetitor
          : newCompetitor.substr(0, newCompetitor.length - 1);
      if (newItem.length > 0) {
        let competitorsList = competitors;
        competitorsList.push(newItem);
        //přidáváme protokol, aby se načetla favicona a www
        // competitorsList.push({domain: newItem, img: "https://www."+newItem.replace("http://", "").replace("https://", "").replace("www","")+"/favicon.ico"});
        updateCompetitors(competitorsList);
      }
      setNewCompetitor('');
    }
  }

  function removeCompetitor(index: number) {
    let competitorsList = competitors;
    competitorsList.splice(index, 1);
    updateCompetitors([...competitorsList]);
  }

  function addCompetitorHinted(url: string) {
    let competitorsList = [...competitors];
    if (competitorsList.indexOf(url) >= 0) {
      competitorsList.splice(competitorsList.indexOf(url), 1);
    } else {
      competitorsList.push(url);
    }
    updateCompetitors(competitorsList);
  }

  return (
    <div
      className={classNames(
        'project-new-inner project-new-competitors card',
        styles.ProjectNewCompetitors
      )}
      style={{ height: 'calc(100% - 96px)' }}
    >
      <Illustration
        className={classNames('illustration', styles.illustration)}
        src="ProjectNewCompetitor"
        alt="illustration"
      />
      <h5 className="text-center">Vyberte své konkurenty</h5>
      <p>
        Znalost Vaší přímé konkurence umožní Eateru správné nastavení
        dashboardua klíčových slov. Své konkurenty můžete vybrat ze seznamu či
        je zadat manuálně.
      </p>
      <p className={styles.counter}>
        {competitors.length}/{planLimit[plan]}
      </p>
      <div className="new-competitor">
        <div className="input-group competitors-group">
          <div className="list">
            {competitors.map((single, i) => {
              return (
                <Segment
                  key={`compentitor-${i}`}
                  design="active"
                  label={single}
                  onClick={() => removeCompetitor(i)}
                />
              );
            })}
            <input
              id="competitor-domain"
              className="input input-colorful"
              type="text"
              name="competitor-domain"
              placeholder="Konkurence"
              onChange={(e) => setNewCompetitor(e.target.value)}
              value={newCompetitor}
              onKeyUp={(event) => updateCompetitorsInner(event)}
            />
          </div>
          {/* <div className="counter">
                        <p>
                            {competitors.length}/{planLimit[plan]} 
                        </p>
                    </div> */}
        </div>
      </div>
      {/* <div className="hint-list">
                {(Array.isArray(hintList)?Object.keys(hintList).map((key)=> {
                    return (<button key={"competitor-"+key} className="competitor" onClick={() =>addCompetitorHinted(hintList[key].url)}>
                        <div className="competitor-img">
                            <img src={hintList[key].img} alt="favicon" />
                        </div>
                        <p className="competitor-name">
                            {hintList[key].url}
                        </p>
                        <p className="competitor-state">
                            {competitors.indexOf(hintList[key].url)>=0?
                            <span className="increase-color">Added succesfully</span>:
                            <span>Add to list</span>}
                        </p>
                    </button>)
                }):"")}
            </div> */}
    </div>
  );
};

export default NewProjectCompetitors;
