import classNames from 'classnames';
import React, { FC, PropsWithChildren, ReactNode, useState } from 'react';
import styles from './Heading.module.scss';
import IOffset from '../../interfaces/IOffset';
import stylesGrid from '../../style/Grid.module.scss';
import IconButton, { IconButtonDesign } from '../button/IconButton';
import Modal from '../modal/Modal';
import { IconType } from '../../enums/IconType';

export type HeadingTag = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';

type HeadingProps = PropsWithChildren<{
  type: HeadingTag;
  info?: string; //deprecated
  content?: ReactNode;
  color?: string;
  iconDesign?: IconButtonDesign;
  marginBottom?: IOffset;
  className?: string;
}>;

const Heading: FC<HeadingProps> = ({
  type,
  info,
  color,
  marginBottom,
  children,
  className,
  content,
}) => {
  const Tag = type as HeadingTag;

  const [modalActive, setModalActive] = useState<boolean>(false);

  return (
    <Tag
      style={{
        color: color,
      }}
      className={classNames(
        styles.Heading,
        marginBottom && stylesGrid[`marginBottom__${marginBottom}`],
        className
      )}
    >
      {children}
      {(info || content) && (
        <>
          <IconButton
            onClick={() => setModalActive(true)}
            icon={IconType.info}
            className={styles.Heading_info}
            design="light"
            offsetLeft="normal"
          />
          <Modal
            dismiss={() => setModalActive(false)}
            dismissOnClick={false}
            isDisabled={!modalActive}
            // heading={children}
            align="center"
          >
            <>
              {info && <p>{info}</p>}
              {content}
            </>
          </Modal>
        </>
      )}
    </Tag>
  );
};
export default Heading;
