import { useState } from 'react';
import Steps from '../../component/steps/Steps';
import Modal from '../../component/modal/Modal';
import NewProjectCompetitors from './comp/NewProjectCompetitors';
import NewProjectDomain from './comp/NewProjectDomain';
import NewProjectKeywords, { ImportType } from './comp/NewProjectKeywords';
import NewProjectLocation from './comp/NewProjectLocation';
import NewProjectSummary from './comp/NewProjectSummary';
import './NewProject.scss';
import LoaderInfinity from '../../component/loader/LoaderInfinity';
import { ProjectPlan } from '../../enums/ProjectPlan';
import { instanceOfIManualmState } from './comp/KeywordsManual';
import Container from '../../component/container/Container';
import Row from '../../component/row/Row';
import Col from '../../component/column/Col';
import Card from '../../component/card/Card';
import Button from '../../component/button/Button';
import { IProject } from '../../interfaces/IProject';
import {
  createProject,
  importKeywordsCollabim,
  importKeywordsFile,
  importKeywordsManual,
} from './NewProjectHandler';
import { IProjectKeyword } from '../../interfaces/IProjectKeyword';
import Menu from '../../layout/menu/Menu';
import classNames from 'classnames';
import styles from './comp/NewProject.module.scss';
import { FlagType } from '../../enums/FlagType';
import { useRecoilValue } from 'recoil';
import { authState } from '../../store/authState';
import { useNavigate } from 'react-router';
import Illustration from '../../component/illustration/Illustration';

export enum LocationScope {
  global = 'global',
  country = 'country',
}

const NewProject = () => {
  const [step, setStep] = useState<number>(1);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [err, setErr] = useState<string>('');

  const [plan, setPlan] = useState<ProjectPlan>(ProjectPlan.PRO);
  const [checkPeriodYearly, setCheckPeriodYearly] = useState<boolean>(true);

  const [name, setName] = useState<string>('');
  const [domain, setDomain] = useState<string>('');

  const [location, setLocation] = useState<FlagType>(FlagType.cze);
  const [locationScope, setLocationScope] = useState<LocationScope>(
    LocationScope.country
  );

  const [competitors, setCompetitors] = useState<Array<string>>([]);
  const [keywordsMethod, setKeywordsMethod] = useState<ImportType>(
    ImportType.FILE
  );
  const [keywordsData, setKeywordsData] = useState<any>(null);

  const [gtoken, setGtoken] = useState<string | null>(null);
  const [gdomain, setGdomain] = useState<string | null>(null);

  const [modalActive, setModalActive] = useState<boolean>(false);

  const { token } = useRecoilValue(authState);

  const navigate = useNavigate();

  function createProjectLocal(): void {
    setIsLoading(true);
    createProject(
      {
        name: name,
        domain: gdomain === null ? domain : gdomain,
        location: location,
        // googleToken: gtoken,
        competitors: competitors,
        projectPricePlan: plan,
      },
      token || ''
    )
      .then((resp: IProject) => {
        switch (keywordsMethod) {
          case ImportType.COLLABIM:
            importCollabim(resp.projectId);
            break;
          case ImportType.FILE:
            importFile(resp.projectId);
            break;
          case ImportType.MANUAL:
            importManual(resp.projectId);
            break;
          default:
            break;
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.error(error);
        setErr('Založení projektu se nezdařilo, zkuste to prosím později.');
      });
  }

  function importManual(projectId: string) {
    try {
      const keywordsMinified = keywordsData.value.replaceAll(', ', ',');
      const keywordsArr: Array<string> = keywordsMinified.split(',');
      importKeywordsManual(projectId, keywordsArr, token || '').then(
        (resp: Array<IProjectKeyword>) => {
          setIsLoading(false);
          navigate(`/projects/${projectId}`, { replace: true });
        }
      );
    } catch (error) {
      console.error(error);
      setIsLoading(false);
      setErr(
        'Import klíčových slov se nezdařil, zkuste to prosím později v nastavení projektu.'
      );
    }
  }
  function importCollabim(projectId: string) {
    try {
      importKeywordsCollabim(
        projectId,
        keywordsData.apiKey,
        keywordsData.projectId,
        token || ''
      ).then((resp: Array<IProjectKeyword>) => {
        setIsLoading(false);
        navigate(`/projects/${projectId}`, { replace: true });
      });
    } catch (error) {
      console.error(error);
      setIsLoading(false);
      setErr(
        'Import klíčových slov se nezdařil, zkuste to prosím později v nastavení projektu.'
      );
    }
  }
  function importFile(projectId: string): void {
    try {
      importKeywordsFile(projectId, keywordsData.name, token || '').then(
        (resp: Array<IProjectKeyword>) => {
          setIsLoading(false);
          navigate(`/projects/${projectId}`, { replace: true });
        }
      );
    } catch (error) {
      console.error(error);
      setIsLoading(false);
      setErr(
        'Import klíčových slov se nezdařil, zkuste to prosím později v nastavení projektu.'
      );
    }
  }
  // console.log(gtoken);
  return (
    // <div className="main-view">
    <div className={styles.NewProjectWrapper}>
      <Menu notWide />
      <div
        id={'main.project-new'}
        className={'main main-wide main-wide-centered new-projects-main'}
        key={'main.project-new'}
      >
        {step === 0 ? (
          <Container>
            {/* <Row>
                            <Col width={12}>
                                <PlanSelector
                                    className="plan-selector-wide"
                                    plan={plan}
                                    setPlan={setPlan}
                                    period={checkPeriodYearly}
                                    setPeriod={setCheckPeriodYearly}
                                />
                            </Col>
                        </Row>
                        <Row
                            isGrid={false}
                            offset='normal'
                            className='plan-selector-btn'
                        >
                            <Button
                                onClick={()=>setStep(1)}
                                design='cta'
                            >
                                Založit projekt
                            </Button>
                        </Row> */}
          </Container>
        ) : (
          <>
            <Row className={styles.NewProject}>
              <Col width={1} />
              <Col width={8}>
                <Card padding="none" className="new-project-maincard">
                  {/* jednotlivé obrazovky */}
                  {step === 1 ? (
                    <NewProjectDomain
                      domain={domain}
                      setDomain={setDomain}
                      name={name}
                      setName={setName}
                    />
                  ) : null}
                  {step === 2 ? (
                    <NewProjectLocation
                      domain={domain}
                      location={location}
                      locationScope={locationScope}
                      updateLocation={setLocation}
                      updateLocationScope={setLocationScope}
                    />
                  ) : null}
                  {step === 3 ? (
                    <NewProjectCompetitors
                      domain={domain}
                      competitors={competitors}
                      updateCompetitors={setCompetitors}
                      plan={plan}
                    />
                  ) : null}
                  {step === 4 ? (
                    <NewProjectKeywords
                      importType={keywordsMethod}
                      setImportType={(method) => {
                        setKeywordsMethod(method);
                        setKeywordsData(null);
                      }}
                      data={keywordsData}
                      setData={setKeywordsData}
                      plan={plan}
                    />
                  ) : null}
                  {/* {step===5?<NewProjectGauth
                                    setToken={setGtoken}
                                    setGdomain={setGdomain}
                                    />:null} */}
                  {step === 5 ? (
                    <NewProjectSummary
                      isTokenSet={gdomain !== null}
                      gAuthReturn={() => setStep(5)}
                      domain={domain}
                      name={name}
                      plan={plan}
                      location={location}
                      competitorsCount={competitors.length}
                      create={createProjectLocal}
                      keywordsCount={
                        keywordsMethod === ImportType.MANUAL &&
                        instanceOfIManualmState(keywordsData)
                          ? keywordsData.count
                          : undefined
                      }
                    />
                  ) : null}
                  {/* konec jednotlivých */}
                </Card>
              </Col>
              <Col width={3}>
                <Card padding="narrow" className={styles.StepsContainer}>
                  <Steps
                    list={[
                      'Název projektu',
                      'Oblast',
                      'Konkurence',
                      'Klíčová slova',
                      'Shrnutí',
                    ]}
                    current={step - 1}
                    skipped={[
                      domain && name ? 9999 : 0,
                      location ? 9999 : 1,
                      competitors.length > 0 ? 9999 : 2,
                      keywordsData !== null ? 999999 : 3,
                    ]}
                    goTo={(i) => setStep(i + 1)}
                  />
                </Card>
              </Col>
            </Row>
            <Row
              offset="normal"
              isGrid
              className={styles.StepsNavigate_container}
            >
              <Col width={1}></Col>
              <Col
                width={11}
                className={classNames(
                  styles.StepsNavigate,
                  step === 5 && styles.ProjectSum
                )}
              >
                {step === 5 ? (
                  <div>
                    <Illustration
                      className="illustration"
                      src="ProjectNewSummary"
                    />
                  </div>
                ) : (
                  <>
                    <Button
                      disabled={step < 1}
                      onClick={() => setStep(step - 1)}
                      design="link"
                    >
                      O krok zpět
                    </Button>
                    <div style={{ textAlign: 'right' }}>
                      {step === 5 && gdomain === null ? (
                        <Button
                          onClick={() => setStep(step + 1)}
                          design="secondary"
                        >
                          Přeskočit
                        </Button>
                      ) : (
                        <Button onClick={() => setStep(step + 1)}>
                          Další krok
                        </Button>
                      )}
                    </div>
                  </>
                )}
              </Col>
            </Row>
          </>
        )}
        <Modal
          isDisabled={err === ''}
          dismiss={() => setErr('')}
          heading="Chyba"
          content={err}
        />
        {isLoading ? <LoaderInfinity /> : null}
      </div>
      {/* </div> */}
    </div>
  );
};
export default NewProject;
