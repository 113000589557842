import { find } from 'lodash';
import { FC } from 'react';
import { FlagType } from '../../../enums/FlagType';
import { ProjectPlan } from '../../../enums/ProjectPlan';
import Button from '../../../component/button/Button';
import Col from '../../../component/column/Col';
import Row from '../../../component/row/Row';
import styles from './NewProject.module.scss';
import { countryList } from './NewProjectLocation';

interface NewProjectSummaryProps {
  isTokenSet: boolean;
  gAuthReturn: () => void;
  domain: string;
  name: string;
  plan: ProjectPlan;
  competitorsCount: number;
  keywordsCount?: number;
  location: FlagType;
  create: () => void;
}

const NewProjectSummary: FC<NewProjectSummaryProps> = ({
  //   isTokenSet,
  //   gAuthReturn,
  location,
  domain,
  name,
  //   plan,
  competitorsCount,
  keywordsCount,
  create,
}) => {
  // function NewProjectSummary({isTokenSet, gAuthReturn, domain, name, plan, competitorsCount, keywordsCount}) {

  // const [price, setPrice] = useState(150);
  // const [isGHelper, setIsGHelper] = useState(false);

  // useEffect(() => {
  //     let priceLocal = 0;
  //     if(plan === ProjectPlan.PRO){
  //         priceLocal = 100;
  //     }else if(plan === ProjectPlan.ESSENTIALS){
  //         priceLocal = 10
  //     }
  //     setPrice(priceLocal)
  // }, [plan, competitorsCount, keywordsCount])

  return (
    <div className={styles.NewProjectSummary}>
      <h5>
        Shrňme si to{' '}
        <span aria-label="check mark" role="img">
          ✅
        </span>
      </h5>
      <div className="summary-grid">
        <Row>
          <Col width={4}>
            <p>Doména</p>
          </Col>
          <Col width={8}>
            <p className={styles.col__right}>{domain}</p>
          </Col>
          <Col width={12}>
            <hr />
          </Col>
          <Col width={4}>
            <p>Název projektu</p>
          </Col>
          <Col width={8}>
            <p className={styles.col__right}>{name}</p>
          </Col>
          <Col width={4}>
            <p>Lokace</p>
          </Col>
          <Col width={8}>
            <p className={styles.col__right}>
              {find(countryList, ['value', location])?.label}
            </p>
          </Col>
          <Col width={12}>
            <hr />
          </Col>
          <Col width={4}>
            <p>Počet konkurentů</p>
          </Col>
          <Col width={8}>
            <p className={styles.col__right}>{competitorsCount}</p>
          </Col>
          {keywordsCount !== undefined && (
            <>
              <Col width={4}>
                <p>Počet klíčových slov</p>
              </Col>
              <Col width={8}>
                <p className={styles.col__right}>{keywordsCount}</p>
              </Col>
            </>
          )}
          {/* {!isTokenSet?<>
                        <Col width={12}>
                            <hr/>
                        </Col>
                        <Col width={4}>
                            <p>Google účet</p>
                        </Col>
                        <Col width={8}>
                            <p className={styles.col__right}>
                                <button onClick={gAuthReturn} className="btn btn-link">Připojit</button>
                                <button onClick={()=>setIsGHelper(!isGHelper)} className="btn-explain">!</button>
                            </p>
                        </Col>
                    </>:null} */}
          {/* <Col width={12}>
                        <hr/>
                    </Col> */}
          {/* <Col width={4}>
                        <p className='bold sum'>Σ</p>
                    </Col>
                    <Col width={8}>
                        <p className={styles.col__right}><b>${price}</b>per month</p>
                    </Col> */}
          <Col width={12}>
            <div className={styles.create}>
              <Button onClick={create} design="cta">
                Založit pojekt
              </Button>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default NewProjectSummary;
