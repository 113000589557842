import classNames from 'classnames';
import React, { FC } from 'react';
import stylesGrid from "../../style/Grid.module.scss";
import IOffset from '../../interfaces/IOffset';

interface ContainerProps {
    className?: string;
    type?: "normal" | "fluid";
    overflowX?: "visible" | "hidden" | "scroll";
    gap?: boolean;
    offset?: IOffset;
    scrollbarHidden?: boolean;
    children: any;
}

const Container : FC<ContainerProps> = ({
    className, 
    type = "normal",
    overflowX = "hidden",
    gap,
    offset,
    scrollbarHidden = true,
    children,
}) => {

    return(
        <div className={classNames(
            stylesGrid.container,
            type === "fluid" && stylesGrid.containerFluid,
            overflowX && stylesGrid[`container__x${overflowX}`],
            offset && stylesGrid[`offset__${offset}`],
            gap && stylesGrid.gap,
            scrollbarHidden && stylesGrid.scrollbarHidden,
            className && className,
        )}
        >
            {children}
        </div>
    );
}
export default Container;