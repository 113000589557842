import React, { FC } from 'react';
import './style/main.scss';
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useLocation,
} from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import Primary from './layout/Page/Primary';
import NewProject from './projects/NewProject/NewProject';
import { authValue } from './store/authState';
import RefreshTokenHandler from './layout/login/RefreshTokenHandler';
// import ProjectDetailWrapper from './projects/detail/ProjectDetailWrapper';
// import AnalysisWrapper from './comp/AnalysisWrapper';
// import Logout from './layout/logout/Logout';
const ProjectDetailWrapper = React.lazy(
  () => import('./projects/detail/ProjectDetailWrapper')
);
const PasswordRecoveryFinish = React.lazy(
  () => import('./tools/passwordRecovery/PasswordRecoveryFinish')
);
const PasswordRecovery = React.lazy(
  () => import('./tools/passwordRecovery/PasswordRecovery')
);
const Logout = React.lazy(() => import('./layout/logout/Logout'));
const AnalysisWrapper = React.lazy(() => import('./component/AnalysisWrapper'));
const ProjectsList = React.lazy(() => import('./projects/ProjectsList'));
const Login = React.lazy(() => import('./layout/login/Login'));
const UserRegister = React.lazy(
  () => import('./tools/UserRegister/UserRegister')
);
const UserVerify = React.lazy(() => import('./tools/UserRegister/UserVerify'));
const SettingsWrapper = React.lazy(
  () => import('./tools/settings/SettingsWrapper')
);
const UserProfile = React.lazy(() => import('./tools/user/UserProfile'));
const Home = React.lazy(() => import('./layout/home/Home'));

const App = () => {
  // const [isLoading, setIsLoading] = useState<boolean>(true);
  // const [isFatal, setIsFatal] = useState<boolean>(false);
  // const [err, setErr] = useState<string>("");

  // const [user, setUser] = useRecoilState(userState);

  //   const {
  //     isAuthenticated
  // } = useRecoilValue(userValue);

  // if(isFatal){

  // }else if(isLoading){

  // }
  return (
    <>
      <DndProvider backend={HTML5Backend}>
        <BrowserRouter>
          <Routes>
            <Route element={<RefreshTokenHandler />}>
              <Route
                path="/login"
                element={
                  <Out>
                    <Login />
                  </Out>
                }
              />
              <Route
                path="/password-reset/*"
                element={
                  <Out>
                    <PasswordRecoveryFinish />
                  </Out>
                }
              />
              <Route
                path="/password-reset/"
                element={
                  <Out>
                    <PasswordRecovery />
                  </Out>
                }
              />

              <Route
                path="/register"
                element={
                  <Out>
                    <UserRegister />
                  </Out>
                }
              />
              <Route
                path="/verify/*"
                element={
                  <Out>
                    <UserVerify />
                  </Out>
                }
              />
              <Route element={<Primary />}>
                <Route
                  path="/"
                  element={
                    <RequireAuth>
                      <Out>
                        <Home />
                      </Out>
                    </RequireAuth>
                  }
                />
                <Route
                  path="analysis/*"
                  element={
                    <RequireAuth>
                      <AnalysisWrapper />
                    </RequireAuth>
                  }
                />
                <Route
                  path="projects/"
                  element={
                    <RequireAuth>
                      <ProjectsList />
                    </RequireAuth>
                  }
                />
                <Route
                  path="projects/*"
                  element={
                    <RequireAuth>
                      <ProjectDetailWrapper />
                    </RequireAuth>
                  }
                />
                <Route
                  path="settings/*"
                  element={
                    <RequireAuth>
                      <SettingsWrapper />
                    </RequireAuth>
                  }
                />
                <Route
                  path="user-profile/*"
                  element={
                    <RequireAuth>
                      <UserProfile />
                    </RequireAuth>
                  }
                />
                <Route
                  path="logout/"
                  element={
                    <RequireAuth>
                      <Logout />
                    </RequireAuth>
                  }
                />
              </Route>
              <Route element={<Primary />}>
                <Route
                  path="projects/new"
                  element={
                    <RequireAuth>
                      <NewProject />
                    </RequireAuth>
                  }
                />
              </Route>
              <Route path="*" element={<Navigate to="/" />} />
            </Route>
            {/* <Route element={
                  <RefreshTokenHandler />
                }/> */}
          </Routes>
        </BrowserRouter>
        <div id="portal-root" className="portal" />
      </DndProvider>
    </>
  );
};

export default App;

// const LayoutDefault : FC<{children?: JSX.Element}> = ({
//   children
// }) =>{
//   return(
//   <>
//     <div id='portal-root' className='portal' />
//       <div id="app-root" className="app">
//         <SidePanel />
//         <Outlet />
//         {children}
//         <HeaderBar />
//       </div>
//   </>
//   )
// }

const RequireAuth: FC<{ children: JSX.Element }> = ({ children }) => {
  const { isAuthenticated } = useRecoilValue(authValue);

  let location = useLocation();

  if (!isAuthenticated) {
    return (
      <>
        <Navigate to="/login" state={{ from: location }} replace />;
      </>
    );
  }

  return <Out>{children}</Out>;
};
export const Out: FC<{ children: JSX.Element }> = ({ children }) => (
  <React.Suspense fallback={<></>}>{children}</React.Suspense>
);
