import Illustration from '../../../component/illustration/Illustration';

function NewProjectDomain({ domain, setDomain, name, setName }) {
  return (
    <div className="project-new-inner project-new-domain card">
      <Illustration
        className="illustration"
        src="ProjectNewDomain"
        alt="illustration"
      />
      <div className="input-group">
        <input
          id="domain"
          className="input input-underline text-center"
          type="text"
          name="domain"
          placeholder="Zadej doménu"
          onChange={(e) => setDomain(e.target.value)}
          value={domain}
          style={{
            borderBottom: '2px solid #EFECFA',
            fontSize: '24px',
            lineHeight: '32px',
            fontWeight: 600,
            marginBottom: '32px',
          }}
        />
      </div>
      <div className="input-group">
        <input
          id="name"
          className="input input-underline text-center"
          type="text"
          name="name"
          placeholder="Pojmenuj svůj projekt"
          onChange={(e) => setName(e.target.value)}
          value={name}
        />
      </div>
    </div>
  );
}

export default NewProjectDomain;
