import { find } from 'lodash';
import { FC } from 'react';
import { FlagType } from '../../../enums/FlagType';
import {
  getEnumByIndex,
  getEnumIndex,
} from '../../../helpers/enum/EnumHelpers';
import Illustration from '../../../component/illustration/Illustration';
import Select from '../../../component/select/Select';
import Tabs from '../../../component/tabs/Tabs';
import { LocationScope } from '../NewProject';

interface NewProjectLocationProps {
  domain: string;
  location: FlagType;
  locationScope: LocationScope;
  updateLocation: (nextLocation: FlagType) => void;
  updateLocationScope: (nextScope: LocationScope) => void;
}

export const countryList = [
  { label: 'Česko', value: FlagType.cze },
  { label: 'Slovensko', value: FlagType.svk },
  { label: 'Polsko', value: FlagType.pol },
];

const NewProjectLocation: FC<NewProjectLocationProps> = ({
  domain,
  location,
  locationScope,
  updateLocation,
  updateLocationScope,
}) => {
  return (
    <div className="project-new-inner project-new-location">
      <Illustration
        className="illustration"
        src="ProjectNewLocation"
        alt="illustration"
      />
      <h5 className="text-center">
        Je{' '}
        <span style={{ color: '#F5B746' }} className="secondary">
          {locationScope == LocationScope.global && 'celý svět'}
          {locationScope == LocationScope.country &&
            find(countryList, ['value', location])?.label}
        </span>{' '}
        lokací,
        <br /> na kterou <span className="secondary">{domain}</span> cílí?
      </h5>
      <div className="project-new-location-in">
        <Tabs
          list={[LocationScope.global, LocationScope.country]}
          active={getEnumIndex(LocationScope, locationScope)}
          setActive={(nextIndex) =>
            updateLocationScope(getEnumByIndex(LocationScope, nextIndex))
          }
        />
        {locationScope === LocationScope.country && (
          <div>
            <Select
              className="search-select text-center"
              placeholder="Vyberte oblast"
              isLoading={false}
              isSearchable={true}
              optionSelected={find(countryList, ['value', location])}
              optionList={countryList}
              onChange={(selected) => updateLocation(selected.value)}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default NewProjectLocation;
