import classNames from 'classnames';
import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { IconType } from '../../enums/IconType';
import Icon from '../icon/Icon';
import styles from "./Steps.module.scss";
import {identity} from "lodash";

interface StepsProps {
    list: Array<string>;
    className?: string;
    current: number;
    skipped?: number[];
    goTo?: (index:number)=>void;
}

const Steps : FC<StepsProps> = ({
    list, 
    className, 
    current=0, 
    skipped=[],
    goTo
}) => {

    const [visited, setVisited] = useState<number[]>([]);

    const elementRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
      if(!visited.includes(current)){
        setVisited([...visited, current]);
      }
    }, [current]);

    const stepsHeight = useMemo(() => 
        elementRef?.current?.clientHeight || 0
    , [elementRef?.current?.clientHeight]);
    

    const random : number = parseInt((Math.random()*100000).toString());

    return(
        <div className={classNames(styles.Steps, className && className)} ref={elementRef}>
            {(Array.isArray(list)?Object.keys(list).map((val:string, i:number)=> {
                return (
                    <div 
                        className={classNames(
                            styles.step,
                            visited.includes(i) && styles.step__done,
                            i === current && styles.step__current,
                            goTo && styles.step__clickable,
                            skipped.includes(i) && styles.step__skipped
                            )}
                        key={"step-"+i+"-"+random} onClick={(goTo?()=>goTo(i):identity)}
                    >
                        <div className={styles.step_number}><p>{i+1}</p></div>
                        <p className={styles.step_name}>{list[i]}</p>
                        {i < current && <Icon className={styles.step_status} icon={(skipped && skipped.includes(i) ? IconType.cross : IconType.ok)} />}
                        <div className={styles.step_line} style={{height: stepsHeight/list.length}} />
                    </div>
                )
            }):null)}
        </div>
    );
}
export default Steps;