import React from 'react';
import { IconType } from '../../enums/IconType';
import Icon from '../../component/icon/Icon';
import styles from './ErrorBoundary.module.scss';

export class ErrorBoundaryCritical extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isErr: false,
    };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    console.log('error', error);
    return { isErr: true };
  }
  render() {
    if (this.state.isErr) {
      return (
        <div className={styles.ErrorCritical}>
          <div className={styles.ErrorCritical_center}>
            <Icon icon={IconType.logo} alt="logo broken" />
          </div>
          <div className={styles.ErrorCritical_heading}>
            <h2 className={styles.ErrorCritical_heading}>
              Houston, we have a problem...
            </h2>
            <p>No worries t will be fixed soon.</p>
          </div>
        </div>
      );
    } else {
      return this.props.children;
    }
  }
}
