import React from 'react';
import classNames from 'classnames';
import { useLocation } from 'react-router-dom';
import { IconType } from '../../enums/IconType';
import ButtonLink from '../../component/button/ButtonLink';
import Icon from '../../component/icon/Icon';
import styles from './SidePanel.module.scss';
import Tooltip from '../../component/tooltip/Tooltip';
// import { Tooltip } from '@mui/material';
// import Tooltip from '@reach/tooltip';

const SidePanel = () => {
  const location = useLocation();
  return (
    <div className={styles.SidePanel}>
      <div className={styles.SidePanel_top}>
        <ButtonLink
          design="icon-hover"
          to="/home"
          className={classNames(
            styles.SidePanel_btn,
            location.pathname === process.env.REACT_APP_BASEPATH + '/' &&
              styles.SidePanel_btn__active
          )}
        >
          <Icon icon={IconType.home} alt="Home" />
          <Icon icon={IconType.homeActive} alt="Home" />
        </ButtonLink>
        {/* <Tooltip
                    title="Notifications"
                    placement='right'
                >
                    <span
                        className={classNames(
                            styles.SidePanel_btn, 
                            location.pathname.indexOf(process.env.REACT_APP_BASEPATH+"/projects")===0 && styles.SidePanel_btn__active
                        )}
                    >
                    <Icon icon={IconType.projects} />
                    <Icon icon={IconType.projects} />
                    </span>
                </Tooltip> */}
        <ButtonLink
          design="icon-hover"
          to="/projects"
          className={classNames(
            styles.SidePanel_btn,
            location.pathname.indexOf(
              process.env.REACT_APP_BASEPATH + '/projects'
            ) === 0 && styles.SidePanel_btn__active
          )}
        >
          <Icon icon={IconType.projects} alt="Projects" />
          <Icon icon={IconType.projectsActive} alt="Projects" />
        </ButtonLink>

        <ButtonLink
          design="icon-hover"
          to="/analysis/sov"
          className={classNames(
            styles.SidePanel_btn,
            location.pathname.indexOf(
              process.env.REACT_APP_BASEPATH + '/analysis'
            ) === 0 && styles.SidePanel_btn__active
          )}
        >
          <Icon icon={IconType.analysis} alt="Analysis" />
          <Icon icon={IconType.analysisActive} alt="Analysis" />
        </ButtonLink>
      </div>
      <div className={styles.SidePanel_bot}>
        <ButtonLink
          design="icon-hover"
          to="/user-profile/latest-analysis"
          className={classNames(
            styles.SidePanel_btn,
            location.pathname.indexOf(
              process.env.REACT_APP_BASEPATH + '/user-profile'
            ) === 0 && styles.SidePanel_btn__active
          )}
        >
          <Icon icon={IconType.user} alt="user" />
          <Icon icon={IconType.userActive} alt="user" />
        </ButtonLink>
        <ButtonLink
          design="icon-hover"
          to="/settings/invoice"
          className={classNames(
            styles.SidePanel_btn,
            location.pathname.indexOf(
              process.env.REACT_APP_BASEPATH + '/settings'
            ) === 0 && styles.SidePanel_btn__active
          )}
        >
          <Icon icon={IconType.settings} alt="settings" />
          <Icon icon={IconType.settingsActive} alt="settings" />
        </ButtonLink>
        <ButtonLink
          design="icon-hover"
          to="/logout"
          className={classNames(
            styles.SidePanel_btn,
            location.pathname.indexOf(
              process.env.REACT_APP_BASEPATH + '/logout'
            ) === 0 && styles.SidePanel_btn__active
          )}
        >
          <Icon icon={IconType.logout} alt="logout" />
          <Icon icon={IconType.logoutActive} alt="logout" />
        </ButtonLink>
      </div>
    </div>
  );
};
export default SidePanel;
