import classNames from 'classnames';
import { FC, PropsWithChildren } from 'react';
import IOffset from '../../interfaces/IOffset';
import stylesGrid from '../../style/Grid.module.scss';

type ColProps = PropsWithChildren & {
  className?: string;
  width?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  offset?: IOffset;
  marginBottom?: IOffset;
};

const Col: FC<ColProps> = ({
  className,
  width,
  offset,
  marginBottom,
  children,
}) => {
  return (
    <div
      className={classNames(
        stylesGrid.Col,
        width && stylesGrid[`col${width}`],
        offset && stylesGrid[`offset__${offset}`],
        marginBottom && stylesGrid[`marginBottom__${marginBottom}`],

        className && className
      )}
    >
      {children}
    </div>
  );
};
export default Col;
