import classNames from 'classnames';
import React, { FC } from 'react';
import { Outlet } from 'react-router';
import HeaderBar from '../headerBar/HeaderBar';
import SidePanel from '../sidePanel/SidePanel';
import Empty, { LayoutProps } from './Empty';
import styles from './Layout.module.scss';

interface PrimaryProps extends LayoutProps {
  wide?: boolean;
  toggle?: () => void;
}

export const Primary: FC<PrimaryProps> = ({ children, className }) => {
  return (
    <Empty
      className={classNames(styles.Layout__primary, className && className)}
    >
      <>
        <div id="portal-root" className="portal" />
        <div id="app-root" className="app">
          <SidePanel />
          <Outlet />
          {children}
          <HeaderBar />
          <div className="appShadow" />
        </div>
      </>
    </Empty>
  );
};
export default Primary;
