import classNames from 'classnames';
import React, { FC } from 'react';
import { IconType } from '../../enums/IconType';
import Icon from '../../component/icon/Icon';
import styles from './Menu.module.scss';
import MenuItem, { MenuItemPlannedProps, MenuItemProps } from './MenuItem';
import MenuItemPlanned from './MenuItemPlanned';

interface MenuProps {
  heading?: string;
  items?: Array<MenuItemProps>;
  itemsPlanned?: Array<MenuItemPlannedProps>;
  toggleWide?: () => void;
  notWide?: boolean;

  className?: string;
  children?: any;
}

const Menu: FC<MenuProps> = ({
  heading,
  items,
  itemsPlanned,
  toggleWide,
  notWide,

  className,
  children,
}) => {
  return (
    <div
      className={classNames(
        styles.Menu,
        notWide && styles.Menu__narrow,
        className && className
      )}
    >
      <div className={styles.Menu_in}>
        {heading && <h3 className={styles.Menu_heading}>{heading}</h3>}
        <div className={styles.Menu_items}>
          {items && (
            <ul>
              {items.map((single, i) => {
                return (
                  <MenuItem
                    key={`menu-item-${i}-${single.name}`}
                    name={single.name}
                    link={single.link}
                    icon={single.icon}
                    create={single.create}
                    disabled={single.disabled}
                  />
                );
              })}
              {itemsPlanned?.map((single, i) => {
                return (
                  <MenuItemPlanned
                    key={`menu-itemPlanned-${i}-${single.name}`}
                    info={single?.info}
                    name={single.name}
                  />
                );
              })}
            </ul>
          )}
          {children}
        </div>
        <div className={styles.Menu_cover}></div>
        {toggleWide && (
          <button className={styles.Menu_toggle} onClick={() => toggleWide()}>
            <Icon
              className={styles.Menu_togleWide}
              icon={IconType.menuToggle}
              alt="Toggle menu wide/narrow"
            />
          </button>
        )}
      </div>
    </div>
  );
};
export default Menu;
