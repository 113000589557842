import classNames from 'classnames';
import { ButtonHTMLAttributes, FC, useState } from 'react';
import { IconType } from '../../enums/IconType';
import Icon from '../icon/Icon';
import styles from './Button.module.scss';
import { CircularProgress } from '@mui/material';
import { Color } from '../../enums/Color';
// import "./Button.css";

export type ButtonDesign =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'cta'
  | 'primary-outline'
  | 'none'
  | 'icon-hover'
  | 'table-click-through'
  | 'link';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  design?: ButtonDesign;
  size?: 'small' | 'medium' | 'big';
  icon?: IconType;
  iconBefore?: IconType;
  iconAfter?: IconType;
  disabled?: boolean;
  onHoverText?: string;
  weight?: 'normal' | 'light';
  isLoading?: boolean;
  offsetLeft?: 'normal';
  display?: 'inline' | 'inline-block' | 'block';
  type?: 'button' | 'reset' | 'submit';
  className?: string;
}

const Button: FC<ButtonProps> = ({
  children,
  onClick,

  design = 'primary',
  size = 'medium',
  display = 'inline',
  icon,
  iconBefore,
  iconAfter,
  disabled = false,
  onHoverText,
  weight,
  isLoading,
  offsetLeft,
  type = 'button',
  className,
  ...props
}) => {
  const [isHover, setIsHover] = useState<boolean>(false);
  const loaderColor = design === 'primary' ? 'white' : Color.primary;

  return (
    <button
      className={classNames(
        styles.btn,
        styles[`btn__${design}`],
        styles[`btn__${display}`],
        styles[`btn__${size}`],
        (iconBefore || iconAfter) && styles.btn__hasIcon,
        icon !== undefined && styles.btn__icon,
        iconBefore && styles.btn__iconBefore,
        iconAfter && styles.btn__iconAfter,
        offsetLeft && styles[`btn__offsetLeft${offsetLeft}`],
        weight && styles[`btn__weight${weight}`],

        className && className
      )}
      type={type}
      disabled={disabled}
      onClick={onClick}
      onMouseOver={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      {...props}
    >
      {iconBefore && <Icon icon={iconBefore} isWhite={design === 'primary'} />}
      {icon && <Icon icon={icon} isWhite={design === 'primary'} />}
      {!icon && ((isHover && onHoverText) || children)}
      {iconAfter && <Icon icon={iconAfter} isWhite={design === 'primary'} />}
      {isLoading && (
        <CircularProgress
          style={{ color: loaderColor }}
          thickness={10}
          size={15}
        />
      )}
    </button>
  );
};
export default Button;
