import {atom, selector} from "recoil";
import IAuthUser from "../interfaces/IAuthUser";
import { emptyUserState } from "./emptyUserState";

export const userState = atom({
  key : "userState",
  default: emptyUserState as IAuthUser });

export const userValue = selector({
  key: "userStateGet",
  // get: ({get}) =>{
  //   const token = get(userState).token;
  //   const fullName = get(userState).user.firstName+" "+get(userState).user.surname;
  //   const roles = get(userState).user.roles;
  //   const isAdmin = roles.indexOf("ROLE_ADMIN")>0;

  //   return{
  //     token,
  //     fullName,
  //     isAdmin: (roles.indexOf("ROLE_ADMIN")>=0)
  //   }
  // }
  get: ({ get }) => ({
    fullName: get(userState).firstName===null || get(userState).surname === null ? null : get(userState).firstName+" "+get(userState).surname,
    email: get(userState)?.email,
    phone: get(userState)?.phone,
    userId: get(userState)?.userId,
    agencyName: get(userState)?.agencyName,
    imageUrl: get(userState).imageUrl,
    roles: get(userState).roles,
    userName: get(userState).username,
    firstName: get(userState).firstName,
    surname: get(userState)?.surname,
    creditBudget: get(userState).creditBudget, // TODO: odstranit
    availableCredits: get(userState).availableCredits, // TODO: odstranit
  }),
});