import classNames from 'classnames';
import {
  AnchorHTMLAttributes,
  FC,
  HTMLAttributeAnchorTarget,
  PropsWithChildren,
} from 'react';
import { Link, To } from 'react-router-dom';
import { IconType } from '../../enums/IconType';
import Icon from '../icon/Icon';
import { ButtonDesign } from './Button';
import styles from './Button.module.scss';

type ButtonLinkProps = AnchorHTMLAttributes<HTMLAnchorElement> &
  PropsWithChildren & {
    to: To;

    disabled?: boolean;
    design?: ButtonDesign;
    designType?: 'button' | 'icon-button' | 'icon-before' | 'icon-after';
    size?: 'small' | 'medium';
    weight?: 'normal' | 'light';
    iconAfter?: IconType;

    display?: 'inline' | 'inline-block' | 'block';
    className?: string;
    target?: HTMLAttributeAnchorTarget;
  };

const ButtonLink: FC<ButtonLinkProps> = ({
  children,
  to,
  disabled,
  design = 'primary',
  size = 'medium',
  display = 'inline',
  weight,
  className,
  target,
  iconAfter,
  ...props
}) => {
  // const navigate = useNavigate();

  // function handleHref(e:any){
  //     if(!to.startsWith("http")){
  //         e.preventDefault();
  //         navigate(to, { replace: true });
  //     }else if(disabled){
  //         e.preventDefault();
  //     }
  // }

  return (
    <>
      <Link
        to={to}
        target={target}
        className={classNames(
          styles.btn,
          styles.btnLink,
          styles[`btn__${design}`],
          styles[`btn__${display}`],
          styles[`btn__${size}`],
          disabled && styles.btn__disabled,
          weight && styles[`btn__weight${weight}`],
          iconAfter && styles.btn__iconAfter,

          className && className
        )}
        // onClick={handleHref}
        {...props}
      >
        {children}
        {iconAfter && <Icon icon={iconAfter} isWhite={design === 'primary'} />}
      </Link>
    </>
  );
};
export default ButtonLink;

export interface ButtonLinkExternalProps extends ButtonLinkProps {
  to: string;
}

export const ButtonLinkExternal: FC<ButtonLinkExternalProps> = ({
  children,
  to,
  design = 'primary',
  size = 'medium',
  display = 'inline',
  className,
}) => {
  return (
    <a
      href={to}
      className={classNames(
        styles.btn,
        styles.btnLink,
        styles[`btn__${design}`],
        styles[`btn__${display}`],
        styles[`btn__${size}`],
        className && className
      )}
    >
      {children}
    </a>
  );
};
