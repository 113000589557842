export enum Color{
    primary= "#2D0E6B",
    secondary= "#F5B746",
    auxilary= "#EFECFA",
    textBackground= "#F7F5FC",

    success= "#67DF42",
    success500 = "#E1F9D9",
    alert= "secondary",
    error= "#DE4952",
    error100= "#F8DBDC",

    white= "#FFF",
    black= "#000000",

    primary100= "#C0B7D3",
    primary200= "#9687B5",
    primary300= "#6C5697",
    primary400= "#42267A",
    primary500= primary,
    primary600= "#290D60",
    primary700= "#240B56",
    primary800= "#1F0A4B",
    primary900= "#1B0840",

    secondary100= "#FDF1DA",
    secondary200= "#FBE2B5",
    secondary300= "#F9D490",
    secondary500= "#F5B746",
}