import classNames from 'classnames';
import React, { FC } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { IconType } from '../../enums/IconType';
import Icon from '../../component/icon/Icon';
import styles from './Menu.module.scss';

export interface MenuItemPlannedProps extends MenuItemProps {
  info?: any;
}

export interface MenuItemProps {
  active?: boolean;
  name: string;
  link: string;
  icon?: IconType;
  create?: boolean;
  disabled?: boolean;
}

export const MenuItem: FC<MenuItemProps> = ({
  active,
  name,
  link,
  icon,
  create,
  disabled,
}) => {
  const { pathname } = useLocation();
  function clickMenu(evt: any) {
    const { target } = evt;
    if (target.tagName === 'LI') {
      target.getElementsByTagName('a')[0].click();
    }
  }

  const linkTarget =
    link.indexOf('http') === 0
      ? link
      : `${process.env.REACT_APP_BASEPATH}${link}`;
  const current = pathname.indexOf(link) === 0;
  return (
    <li
      className={classNames(
        styles.MenuItem,
        disabled && styles.MenuItem__disabled,
        (active || current) && styles.MenuItem__active,
        create && styles.Menu__canCreate
      )}
      onClick={clickMenu}
    >
      {icon && <Icon icon={icon} alt={`Menu item icon (${name})`} />}
      <Link
        className={styles.MenuItem_link}
        to={disabled ? '' : linkTarget}
        onClick={(e) => {
          disabled && e.preventDefault();
        }}
      >
        {name}
      </Link>
      {create && (
        <Link
          title="vytvořit analýzu"
          className={styles.MenuItem_create}
          to={disabled ? '' : link + '/create'}
        >
          <Icon icon={IconType.plus} alt="Create analysis" />
          {/* <img className="menu-logo" src={process.env.PUBLIC_URL+"/svgs/bold/Plus.svg"} alt="Create piko" /> */}
        </Link>
      )}
    </li>
  );
};
export default MenuItem;
