export enum IllustrationType {
  AnalysisNew = 'AnalysisNew',
  TimeManagement = 'TimeManagement',
  AnalysisRun = 'AnalysisRun',
  ChartClimb = 'ChartClimb',
  SovSearch = 'SovSearch',
  RegisterAccAgency = 'RegisterAccAgency',
  RegisterAccCompany = 'RegisterAccCompany',
  RegisterAccFreelance = 'RegisterAccFreelance',
  CheckMail = 'CheckMail',
  ProjectPlan = 'ProjectPlan',
  NewProjectWrong = 'NewProjectWrong',
  ProjectNew = 'ProjectNew',
  AkwSegment = 'akwSegment',
  EmptyChart = 'EmptyChart',
  LoginBg = 'LoginBg',
  LoginBoard = 'LoginBoard',
  LoginCharacter1 = 'LoginCharacter1',
  LoginCharacter2 = 'LoginCharacter2',
  ProjectNewSummary = 'ProjectNewSummary',
  ProjectNewCompetitor = 'ProjectNewCompetitor',
  ProjectNewLocation = 'ProjectNewLocation',
  ProjectNewDomain = 'ProjectNewDomain',
  ProjectNewGauthDomain = 'ProjectNewGauthDomain',
  ProjectNewGauth = 'ProjectNewGauth',
}
declare global {
  type ILLUSTRATIONSPRITE = keyof typeof IllustrationType;
}
