import CircularProgress from '@mui/material/CircularProgress/CircularProgress';
import classNames from 'classnames';
import { FC, useState, ButtonHTMLAttributes } from 'react';
import { Color } from '../../enums/Color';
import { IconType } from '../../enums/IconType';
import Icon from '../icon/Icon';
import styles from './IconButton.module.scss';

export type IconButtonDesign = 'dark' | 'light' | 'transparent';

export type IconButtonSize = 48 | 40 | 32 | 24 | 20 | 16;

// interface IconButtonProps{
interface IconButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  // onClick?: ()=>void;

  design?: IconButtonDesign;
  size?: IconButtonSize;
  icon?: IconType;
  iconHover?: IconType;
  disabled?: boolean;
  notification?: number;

  offsetLeft?: 'normal';
  display?: 'inline' | 'inline-block' | 'block';
  type?: 'button' | 'reset' | 'submit';
  className?: string;
  isLoading?: boolean;
}

export const IconButton: FC<IconButtonProps> = ({
  onClick,

  design = 'dark',
  size = 20,
  display = 'inline',
  icon,
  iconHover,
  disabled = false,
  notification,

  offsetLeft,
  type = 'button',
  className,
  isLoading,
}) => {
  const [isHover, setIsHover] = useState<boolean>(false);
  const loaderColor = design === 'dark' ? 'white' : Color.primary;

  return (
    <button
      className={classNames(
        styles.btn,
        styles.IconBtn,
        styles[`IconBtn__${design}`],
        styles[`IconBtn__${display}`],
        styles[`IconBtn__size${size}`],
        offsetLeft && styles[`btn__offsetLeft${offsetLeft}`],
        isHover && styles.IconBtn__hover,
        notification && styles.IconBtn__notification,
        isLoading && styles.IconBtn__loading,

        className && className
      )}
      type={type}
      disabled={disabled}
      onClick={onClick}
      onMouseOver={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      {!isLoading && (
        <>
          {icon && !(iconHover && isHover) && (
            <Icon icon={icon} isWhite={design === 'dark' && !isHover} />
          )}
          {iconHover && isHover && <Icon icon={iconHover} />}
        </>
      )}
      {isLoading && (
        <CircularProgress
          style={{ color: loaderColor }}
          thickness={10}
          size={15}
        />
      )}
      {notification && (
        <span className={styles.notification}>
          <span>{notification}</span>
        </span>
      )}
    </button>
  );
};
export default IconButton;
