import classNames from 'classnames';
import React, { FC } from 'react';
import styles from "./Spinner.module.scss";
import { Color } from '../../enums/Color';
// import "./Spinner.scss";

interface SpinnerProps {
    className?: string;
    hidden?: boolean;
    color?: Color;
}

const Spinner: FC<SpinnerProps> = ({
    className,
    hidden,
    color,
}) => {
  return(
    <div 
        className={classNames(
            styles.Spinner,
            hidden && styles.Spinner__hidden,
            color && styles[`Spinner_${color}`],
            "Spinner",
            className && className
        )}
        style={{}}
    />
    
  );
}
export default Spinner;