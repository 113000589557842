import React, { FC, useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { RequestMethod } from '../../../enums/RequestMethod';
import { apiBaseUri, call, getApiUri } from '../../../helpers/call/Calls';
import { authValue } from '../../../store/authState';
import DropUpload from '../../../component/dropUpload/DropUpload';
import Loader from '../../../component/loader/Loader';
import LoaderInfinity from '../../../component/loader/LoaderInfinity';
interface KeywordsFileProps {
  data: any;
  setData: (nextData: IFileState | null) => void;
}

export interface IFileState {
  name: string;
  originalName: string;
}
function instanceOfIFileState(object: any): object is IFileState {
  return 'name' in object && 'originalName' in object;
}

interface FilesUploadApi {
  fileStatus: 'UPLOADED' | 'FAILED';
  name: string;
  originalName: string;
  path?: string;
  uploadDate: string;
  url?: string;
}

const KeywordsFile: FC<KeywordsFileProps> = ({ data, setData }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [err, setErr] = useState<string>('');
  const [fileList, setFileList] = useState<any[]>([]);

  const { token } = useRecoilValue(authValue);

  useEffect(() => {
    if (data && instanceOfIFileState(data)) {
      setFileList([
        {
          domain: data.originalName,
          name: data.name,
        },
      ]);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  function handleFileAdd(files: FileList, allowMultiple: boolean) {
    let fileListNext: any[] = [];
    for (let i = 0; i < files.length; i++) {
      const newFile: any = files[i];
      newFile.domain = files[i].name.substr(0, files[i].name.lastIndexOf('.'));
      fileListNext.push(files[i]);
    }
    upload(files);
    //push do seznamu
    setFileList(fileListNext);
  }

  function upload(files: any) {
    // TODO: přetypovat na FileList až budou calls v typescriptu
    setIsLoading(true);
    let formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append('files', files[i]);
    }
    call(
      RequestMethod.POST,
      getApiUri(`${apiBaseUri()}uploads`),
      token,
      undefined,
      false,
      formData
    )
      .then((data) => {
        setIsLoading(false);
        setData({
          name: data?.[0]?.name,
          originalName: data?.[0]?.originalName,
        });
      })
      .catch((err) => {
        console.error('Error', err);
        setErr(
          'Nastal problém při nahrávání souborů, zkuste to prosím později, nebo jinou metodou.'
        );
        setIsLoading(false);
      });
  }

  return (
    <div className="keywords-import-file text-center">
      <DropUpload
        heading={''}
        p={'Pro import klíčových slovstačí kliknout na tlačítko níže.'}
        fileAdd={handleFileAdd}
        fileRemove={() => {
          setData(null);
          setFileList([]);
        }}
        toggleHelp={function (): void {
          throw new Error('Function not implemented.');
        }}
        allowedExt={['xlsx']}
        fileList={fileList}
        allowMultiple={false}
        className="card-no-padding card-no-shadow"
      />
      {err && <p className="text-center decrease-color">{err}</p>}
      {isLoading && <LoaderInfinity />}
    </div>
  );
};

export default KeywordsFile;
