import classNames from 'classnames';
import React, {FC} from 'react';
import { IconType } from '../../enums/IconType';
import styles from "./Icon.module.scss";

interface IconProps{
    icon: IconType;
    alt?: string;
    className?: string;
    isWhite?: boolean;
}

const Icon: FC<IconProps> = ({
    icon,
    alt = "",
    className,
    isWhite = false,
}) => {

    return (
        <svg
            className={classNames(
                styles.icon,
                isWhite && styles.icon__white,
                className
            )}
        >
          <use href={`${process.env.PUBLIC_URL}/svg/svg-sprite.svg#${icon}`} />
          {alt && <title>{alt}</title>}
        </svg>
      );
}
export default Icon;