import React, { FC, PropsWithChildren } from 'react';
import './Loader.css';

type LoaderInfinityProps = PropsWithChildren<{
  heading?: string;
  className?: string;
}>;

const LoaderInfinity: FC<LoaderInfinityProps> = ({
  heading,
  className,
  children,
}) => {
  return (
    <div className={'loader-infinity' + (className ? ' ' + className : '')}>
      <div className="loader-infinity-inner">
        <span className="loader-header">
          {heading ? heading : <>Dej mi vteřinu, jen se tím prokoušu.</>}
        </span>
        <div className="loader-inner">
          <img
            src={
              process.env.PUBLIC_URL + '/imgs/keywordeater_loader_infinity.gif'
            }
            alt="Keyword Eater super beautiful loader animation, that you unfortunately cannot see :("
          />
        </div>
        <div className="loader-bottom">{children}</div>
      </div>
    </div>
  );
};
export default LoaderInfinity;
