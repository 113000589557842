import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { userValue } from '../../store/userState';
import styles from './KeywordsRemaining.module.scss';

const KeywordsRemaining = () => {
  const { creditBudget, availableCredits } = useRecoilValue(userValue);

  const { t } = useTranslation(['common']);

  // console.log(keywordsLimit, availableCredits);

  return (
    <div className={styles.KeywordsRemaining}>
      <h6 className={styles.Main}>
        {availableCredits} / {creditBudget}
      </h6>
      <p className={styles.detail}>{t('remainingKw')}</p>
    </div>
  );
};
export default KeywordsRemaining;
