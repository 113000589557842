import classNames from 'classnames';
import { FC, PropsWithChildren } from 'react';
import { IconType } from '../../enums/IconType';
import IOffset from '../../interfaces/IOffset';
import Button from '../button/Button';
import IconButton from '../button/IconButton';
import styles from "./Card.module.scss";
import stylesGrid from "../../style/Grid.module.scss";

type CardProps = PropsWithChildren&{
    children: any;

    padding?: "normal" | "narrow" | "small" | "minimal" | "none";
    radius?: "normal" | "small";
    shadow?: "normal" | "none";
    border?: "none" | "primary" | "auxilary" | "auxilaryDashed" | "white";
    overflow?: 'hidden' | 'visible' | 'scrollX';
    reportBug?: ()=>void;
    onClick?: ()=>void;
    explain?: ()=>void;
    isRelative?: boolean;
    marginBottom?: IOffset;
    
    className?: string;
}

const Card: FC<CardProps> = ({
    children,
    
    padding = "normal",
    radius = "normal",
    shadow = "normal",
    border,
    overflow,
    reportBug,
    onClick,
    explain,
    isRelative = false,
    marginBottom,

    className,
}) => {

    return (
        <div className={classNames(
            styles.card,
            styles[`card__padding${padding}`],
            styles[`card__radius${radius}`],
            styles[`card__shadow${shadow}`],
            border && styles[`card__border${border}`],
            overflow && styles[`card__of${overflow}`],
            (isRelative || explain || reportBug) && styles.relative,
            marginBottom && stylesGrid[`marginBottom__${marginBottom}`],

            className && className
        )}
            onClick={onClick && onClick}
        >
            {reportBug && <Button type="button" onClick={reportBug} icon={IconType.info} className={styles.card_reportBug} />}
            {explain && <IconButton icon={IconType.info} type='button' design='light' className={styles.card_explain} size={32} onClick={explain} />}
            {children}
        </div>
    );
}
export default Card;