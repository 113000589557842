import React, { FC, ReactElement } from 'react';
import styles from "./TabsVertical.module.scss";
import classNames from 'classnames';
import Button from '../button/Button';

interface TabsVerticalProps{
    list: (string|ReactElement)[];
    className?: string;
    active: number;
    setActive: (next:number)=>void;
}

const TabsVertical : FC<TabsVerticalProps>  = ({
    list, 
    className,
    active,
    setActive,
}) => {

    const random = (Math.random()*100000).toPrecision(1);

    return(
        <div
            className={classNames(
                styles.TabsVertical,
                className && className
            )}
        >
            {list.map((single, i) => {
                return (
                    <Button
                        key={`Tab-${i}-${random}`}
                        design='none'
                        onClick={()=>setActive(i)}
                        className={classNames(
                            styles.Tab,
                            active === i && styles.Tab__active
                        )}
                    >
                        {single}
                    </Button>
                )
            })}
        </div>
    );
}
export default TabsVertical;