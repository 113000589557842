import React, { FC } from 'react';
import { ProjectPlan } from '../../../enums/ProjectPlan';
import Card from '../../../component/card/Card';
import Col from '../../../component/column/Col';
import Row from '../../../component/row/Row';
import TabsVertical from '../../../component/tabs/TabsVertical';
import KeywordsCollabim from './KeywordsCollabim';
import KeywordsFile from './KeywordsFile';
import KeywordsManual from './KeywordsManual';

export enum ImportType {
  FILE,
  COLLABIM,
  MANUAL,
}

interface StepsProps {
  importType: ImportType;
  setImportType: (importType: ImportType) => void;
  setData: (detail: any) => void;
  data: any;
  plan: ProjectPlan;
}

const NewProjectKeywords: FC<StepsProps> = ({
  importType,
  setImportType,
  data,
  setData,
  plan,
}) => {
  return (
    <div className="project-new-inner project-new-keywords">
      <Row>
        <Col width={3}>
          <Card className="keywords-left">
            <h5>Klíčová slova</h5>
            <p>Vyberte jeden ze způsobů importu klíčových slov.</p>
            <TabsVertical
              list={['Nahrání souboru', 'Import z collabimu', 'Ruční zadání']}
              active={importType}
              setActive={setImportType}
            />
          </Card>
        </Col>
        <Col width={5} className="import-out">
          {importType === ImportType.COLLABIM && (
            <div className="keyword-import-collabim">
              <KeywordsCollabim data={data} setData={setData} />
            </div>
          )}
          {importType === ImportType.FILE && (
            <div className="keyword-import-file">
              <KeywordsFile data={data} setData={setData} />
            </div>
          )}
          {importType === ImportType.MANUAL && (
            <div className="keyword-import-manual">
              <KeywordsManual data={data} setData={setData} plan={plan} />
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default NewProjectKeywords;
