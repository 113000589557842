import { changeLanguage } from 'i18next';
import React, { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { IconType } from '../../enums/IconType';
import { useCurrentLng } from '../../hooks/useCurrentLng';
import { userValue } from '../../store/userState';
import Button from '../../component/button/Button';
import ButtonLink from '../../component/button/ButtonLink';
import Flag from '../../component/flag/Flag';
// import { IconButtonLink } from '../../component/button/IconButtonLink';
import Icon from '../../component/icon/Icon';
import styles from './HeaderBar.module.scss';
import KeywordsRemaining from './KeywordsRemaining';

const HeaderBar: FC = () => {
  const { fullName, agencyName, userName } = useRecoilValue(userValue);

  const { t } = useTranslation(['common']);

  const lng = useCurrentLng();

  return (
    <div className={styles.HeaderBar}>
      <ButtonLink design="none" to="/" className={styles.HeaderLogo}>
        <Icon icon={IconType.logo} alt="Keyword Eater logo" />
      </ButtonLink>
      <div className={styles.HeaderMessage}>
        <p>
          {/* <Trans i18nKey="headerMsg">{t('headerMsg')}</Trans> */}
          <Trans t={t} i18nKey="headerMsg" />
        </p>
      </div>
      <div className={styles.HeaderControl}>
        <KeywordsRemaining />
        {/* <IconButtonLink
                    to='/projects/new'
                    design='dark'
                    size={40}
                    icon={IconType.plus}
                /> */}
        <hr />
        {/* <Button
                    className={styles.actionButton}
                    design='icon-hover'
                >
                    <Icon
                        icon={IconType.message}
                        alt="messages"
                    />
                    <Icon
                        icon={IconType.messageActive}
                        alt="messages"
                    />
                </Button> */}
        {/* <Button
                    className={styles.actionButton}
                    design='icon-hover'
                >
                    <Icon
                        icon={IconType.bell}
                        alt="messages"
                    />
                    <Icon
                        icon={IconType.bellActive}
                        alt="messages"
                    />
                </Button> */}
        <Button design="none" className={styles.btnProfile}>
          <div className={styles.profile_img}>
            <img
              className={styles.profile_img}
              // src={process.env.PUBLIC_URL+"/imgs/user/IMG_1293.png"}
              src={process.env.PUBLIC_URL + '/imgs/user/photo.png'}
              alt="User Profile"
            />
          </div>
          <p className={styles.profile_name}>{fullName || userName}</p>
          <p className={styles.profile_company}>
            {agencyName ? agencyName : ' '}
          </p>
          {/* <div className={styles.profile_expand}>
                        <Icon
                            icon={IconType.expand}
                            className={styles.profile_expandPiko}
                            alt="Details expand"
                        />
                    </div> */}
        </Button>
        <div className={styles.lang}>
          {lng === 'cs' && (
            <Button design="link" onClick={() => changeLanguage('en')}>
              <Flag flag="usa" />
            </Button>
          )}
          {lng === 'en' && (
            <Button design="link" onClick={() => changeLanguage('cs')}>
              <Flag flag="cze" />
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};
export default HeaderBar;
