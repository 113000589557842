export const getEnumOptions = function(enumProp: any) : Array<string> {
    // eslint-disable-next-line
    const keys: (keyof typeof enumProp)[] = <(keyof typeof enumProp)[]>Object.keys(enumProp);
    let returnArr : Array<string> = [];
    for (const key of keys) {
        returnArr.push(enumProp[key]);
    }
    return returnArr;
};

export const getEnumByIndex = function(enumProp: any, index:number) : any{
    const enumOptions = getEnumOptions(enumProp);
    return enumOptions[index];
};

export const getEnumContains = function(enumProp: any, checkValue: string | number):any{
    return Object.values(enumProp).includes(checkValue);
}
export const getEnumIndex = (enumProp: any, checkValue: string | number):any => {
    return Object.values(enumProp).indexOf(checkValue);
}
// export const getEnumContainsOption = function(enumProp: any, option: string | number) : boolean{
//     return Object.values(enumProp).includes(option)
// }
// export const getEnumOption = function(enumProp: any, option: string | number) : any{
//     const enumOption : typeof enumProp = enumProp[option as keyof typeof enumProp];
//     return enumOption
// }
// konverze enum na string
// const currentEngine : SearchEngine = SearchEngine[se as keyof typeof SearchEngine];