import React, { FC, useEffect, useState } from 'react';
import { ProjectPlan } from '../../../enums/ProjectPlan';
import Card from '../../../component/card/Card';
const planLimit = {
  [ProjectPlan.PRO]: 250,
  [ProjectPlan.ESSENTIALS]: 100,
  [ProjectPlan.STARTER]: 50,
  [ProjectPlan.CUSTOM]: '',
};

export interface IManulaState {
  value: string;
  count: number;
}
export function instanceOfIManualmState(object: any): object is IManulaState {
  return 'value' in object && 'count' in object;
}

interface KeywordsManualProps {
  data: IManulaState;
  setData: (nextData: IManulaState) => void;
  plan: ProjectPlan;
}

const KeywordsManual: FC<KeywordsManualProps> = ({ data, setData, plan }) => {
  const [count, setCount] = useState<number>(0);
  const [textValue, setTextValue] = useState<string>('');

  useEffect(() => {
    if (data && instanceOfIManualmState(data)) {
      setTextValue(data.value);
      setCount(data.count);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  function parseManualImport(content: string) {
    const countNum = (content.match(/,/g) || []).length;
    setCount(countNum);
    setTextValue(content);
    setData({
      count: countNum,
      value: content,
    });
  }

  return (
    <>
      <p>Vložte jednotlivá slova:</p>
      <p className="counter bold">
        {count.toLocaleString()} / {planLimit[plan].toLocaleString()}
      </p>
      <Card border="auxilary" padding="none" shadow="none">
        <textarea
          onChange={(e) => parseManualImport(e.target.value)}
          className="card card-border card-narrow"
          value={textValue}
        />
      </Card>
    </>
  );
};

export default KeywordsManual;
