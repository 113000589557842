import classNames from 'classnames';
import React, {FC, FocusEventHandler} from 'react';
import styles from "./Select.module.scss";
import {default as ReactSelect} from 'react-select';
import Creatable, { useCreatable } from 'react-select/creatable';
import {Color} from '../../enums/Color';
import {FormFieldState} from "../../enums/FormFieldState";

// import "./Select.scss";

export interface SelectOption {
    value: string | number | undefined;
    label: string;
    [key:string]: any;
    //pro card control přijde isLabel: true
}

// declare type OnChangeValue<SelectOption, IsMulti extends boolean> = IsMulti extends true ? readonly SelectOption[] : SelectOption;
export interface SelectProps {
    className?: string;
    optionList: Array<SelectOption>;
    isMulti?: boolean,
    // onChange: (nextValue: (OnChangeValue<SelectOption, (SelectProps['isMulti'] | false)>)) => void;
    // onChange: (newValue: (SelectOption | readonly SelectOption[] | null)) => void;
    // onChange: (newValue: OnChangeValue<SelectOption, IsMulti>, actionMeta: ActionMeta<Option>) => void;
    onChange: (newVal: any)=>void;
    optionSelected?: SelectOption | SelectOption[];
    optionDefault?: SelectOption;
    isLoading?: boolean;
    isDisabled?: boolean;
    isSearchable?: boolean;
    isFullWidth?: boolean;
    placeholder?: string;
    validationState?: FormFieldState;
    design?: "normal" | "white" | 'CardControl',
    components?: any,
    onFocus?: FocusEventHandler<HTMLInputElement>;
}

const Select: FC<SelectProps> = ({
                                     className,
                                     optionList,
                                     onChange,
                                     optionSelected,
                                     optionDefault,
                                     isLoading,
                                     isDisabled = false,
                                     isSearchable = true,
                                     isFullWidth,
                                     placeholder,
                                     validationState,
                                     design,
                                     components,
                                     isMulti,
                                     onFocus,
                                 }) => {
    return (
        <div className={classNames(
            styles.Select_container,
            styles.Select,
            isFullWidth && styles.Select__fullWidth,
            className && className,
        )}>
            <ReactSelect
                onFocus={onFocus}
                menuPortalTarget={document.body}
                // menuPortalTarget={<div><Portal /></div>}
                className={styles.SelectSearch}
                classNamePrefix="select"
                // classNamePrefix={styles.Select}
                // className={styles.Select}
                placeholder={placeholder}
                isDisabled={isLoading || isDisabled}
                isSearchable={isSearchable}
                name="selector"
                options={optionList}
                noOptionsMessage={({inputValue}) => !inputValue ? "Nic tu není" : "To, co tu hledáš, tu není, ale mohlo by, ale stejně není 😭 ... ale můžeš zkusit něco jiného."}
                defaultValue={optionDefault}
                value={optionSelected}
                onChange={onChange}
                components={components}
                theme={(theme) => ({
                    ...theme,
                    colors: {
                        ...theme.colors,
                        primary25: Color.textBackground,
                        primary: Color.textBackground,
                    },
                })}
                styles={customStyles(validationState, design)}
                isMulti={isMulti}
                isClearable={false}
            />
        </div>

    );
}
export default Select;

export const SelectCreatable: FC<SelectProps> = ({
    className,
    optionList,
    onChange,
    optionSelected,
    optionDefault,
    isLoading,
    isDisabled = false,
    isSearchable = true,
    isFullWidth,
    placeholder,
    validationState,
    design,
    components,
    isMulti,
    onFocus,
}) => {
    return (
        <div className={classNames(
            styles.Select_container,
            styles.Select,
            isFullWidth && styles.Select__fullWidth,
            className && className,
        )}>
        <Creatable
            onFocus={onFocus}
            menuPortalTarget={document.body}
            // menuPortalTarget={<div><Portal /></div>}
            className={styles.SelectSearch}
            classNamePrefix="select"
            // classNamePrefix={styles.Select}
            // className={styles.Select}
            placeholder={placeholder}
            isDisabled={isLoading || isDisabled}
            isSearchable={isSearchable}
            name="selector"
            options={optionList}
            noOptionsMessage={({inputValue}) => !inputValue ? "Nic tu není" : "To, co tu hledáš, tu není, ale mohlo by, ale stejně není 😭 ... ale můžeš zkusit něco jiného."}
            defaultValue={optionDefault}
            value={optionSelected}
            onChange={onChange}
            components={components}
            theme={(theme) => ({
            ...theme,
            colors: {
            ...theme.colors,
            primary25: Color.textBackground,
            primary: Color.textBackground,
            },
            })}
            styles={customStyles(validationState, design)}
            isMulti={isMulti}
            isClearable={false}
        />
        </div>
    );
}


const controlStylesHasError = (hasError: boolean) => (
    hasError ? {background: Color.error100, border: `1px solid ${Color.error}`} : {}
)
const customStyles = (state: FormFieldState | undefined, design?: SelectProps['design']) => ({
    option: (provided: any, state: any) => ({
        ...provided,
        color: "2D0E6B",
        padding: '8px',
        textAlign: 'center',
        textAlignLast: 'center',
        cursor: 'pointer',
        fontWeight: state.isSelected ? 'bold' : 'normal',
    }),
    container: (provided: any) => ({
        ...provided,
    }),
    control: (provided: any, styles: any) => ({
        ...provided,
        minHeight: 33,
        cursor: "pointer",
        background: design === "white" && Color.white || Color.auxilary,
        flexWrap: 'nowrap',
        // zIndex: 2,
        height: 3,
        maxHeight: 33,
        justifyContent: 'center',
        borderRadius: "100px",
        border: `1px solid #e2e2e1`,
        fontWeight: 500,
        fontSize: "14px",
        lineHeight: "21px",
        color: "#2D0E6B",
        padding: "3px 32px",
        // boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.06)",
        transition: "border-radius .3s ease",
        '&:hover:not(.select__control--menu-is-open)': {
            borderColor: Color.primary,
        },
        '&.select__control--menu-is-open': {
            borderRadius: "19px 19px 0 0",
            borderColor: 'red',
            border: `1px solid ${Color.white}`,
            backgroundColor: '#FFF',
            color: "#2D0E6B",
        },
        ...controlStylesHasError(state === FormFieldState.error)
    }),
    input: (provided: any) => ({
        ...provided,
        margin: 0,
        padding: 0
    }),
    valueContainer: (provided: any) => ({
        ...provided,
        flex: 'unset',
        padding: 0
    }),
    placeholder: (provided: any) => ({
        ...provided,
        color: "#2D0E6B"
    }),
    menuPortal: (provided: any) => ({
        ...provided,
        zIndex: 2000,
    }),
    menu: (provided: any) => ({
        ...provided,
        overflow: "hidden",
        // marginTop: 0,
        borderRadius: "0 0 19px 19px",
        // paddingTop: '40px',
        // top: '0',
        // top: "100%"
        // top: "21px",
        top: "-5px",
        // paddingTop: "43px",
        // zIndex: 2
    }),
    dropdownIndicator: (provided: any) => ({
        ...provided,
        color: Color.primary,
        padding: 0,
        svg: {
            width: '16px',
            height: '16px',
        }
    }),
    indicatorSeparator: (provided: any) => ({
        ...provided,
        display: 'none'
    }),
    multiValue: (provided:any, asd:any) => {
        console.log("lala",provided, asd);
        return{
            // ...provided,
            // backgroundColor: "white",
            // color: Color.primary,
        }
    },
    singleValue: (provided: any, state: any) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = 'opacity 300ms';

        return {
            ...provided,
            opacity,
            transition,
            color: "#2D0E6B",
            fontWeight: 600,
        };
    }
})