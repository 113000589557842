import classNames from 'classnames';
import React, { AnchorHTMLAttributes, FC } from 'react';
import { FlagType } from '../../enums/FlagType';
import styles from './Flag.module.scss';

interface FlagProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
  flag: keyof typeof FlagType;
  className?: string;
}

const Flag: FC<FlagProps> = ({ flag, className }) => {
  return (
    <div className={classNames(styles.Flag, className && className)}>
      <img
        src={`${process.env.PUBLIC_URL}/svgs/flags/${flag}.svg`}
        alt="Country flag"
      />
    </div>
  );
};
export default Flag;
