import classNames from 'classnames';
import React, { FC, PropsWithChildren } from 'react';
import IOffset from '../../interfaces/IOffset';
import stylesGrid from '../../style/Grid.module.scss';

type RowProps = PropsWithChildren<{
  className?: string;
  isGrid?: boolean;
  marginBottom?: IOffset;
  //should not use
  offset?: IOffset;
  offsetEven?: IOffset;
}>;

const Row: FC<RowProps> = ({
  className,
  isGrid = true,
  marginBottom,
  offset,
  offsetEven,
  children,
}) => {
  return (
    <div
      className={classNames(
        stylesGrid.row,
        isGrid && stylesGrid.grid,
        offset && stylesGrid[`offset__${offset}`],
        offsetEven && stylesGrid[`offsetEven__${offsetEven}`],
        marginBottom && stylesGrid[`marginBottom__${marginBottom}`],
        className && className
      )}
    >
      {children}
    </div>
  );
};
export default Row;
