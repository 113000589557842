import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import './DropUpload.scss';
import stylesBtn from '../button/Button.module.scss';
import IconButton from '../button/IconButton';
import { IconType } from '../../enums/IconType';
import Spinner from '../spinner/Spinner';
interface IUploadFileList {
  domain: string;
}

function DropUpload({
  heading,
  p,
  fileRemove,
  changeName,
  fileAdd,
  multiFileWarning,
  toggleHelp,
  multiFileWarningCount = 1,
  allowedExt = [],
  fileList = [],
  allowMultiple = false,
  id = '',
  className = '',
  isLoading = false,
}: {
  heading: string;
  p: any;
  fileRemove: (index: number) => void;
  changeName?: (index: number, name: string) => void;
  fileAdd: (files: FileList, allowMultiple: boolean) => void;
  multiFileWarning?: string;
  toggleHelp?: () => void;
  multiFileWarningCount?: number;
  allowedExt: Array<string>;
  fileList: any[];
  allowMultiple: boolean;
  id?: string;
  className?: string;
  isLoading?: boolean;
}) {
  // const [isLoading, setIsLoading] = useState(false);
  // const [isErr, setIsErr] = useState(false);

  const [isDragOver, setIsDragOver] = useState(false);
  const [AllowedExtList, setAllowedExtList] = useState<string>('');

  useEffect(() => {
    setAllowedExtList('.' + allowedExt.join(',.'));
  }, [allowedExt]);

  //je potřeba rozlišit nad jakým prvkem táhne soubor??
  function drop(e: React.DragEvent): void {
    e.preventDefault();
    setIsDragOver(false);
    handleFileSelect(e.dataTransfer.files);
  }

  function checkExt(name: string) {
    let ext = name.substr(name.lastIndexOf('.') + 1).toLowerCase();
    return allowedExt.indexOf(ext) >= 0;
  }

  function handleFileSelect(files: FileList) {
    let extOk = true;
    console.log(files);
    for (let i = 0; i < files.length; i++) {
      console.log('f', files[i]);
      extOk = checkExt(files[i].name) ? extOk : false;
    }
    if (fileAdd) {
      if (extOk) {
        if (fileAdd) {
          fileAdd(files, allowMultiple);
        } else {
          throw new Error('Chyba, kontaktujte prosím správce.');
        }
      } else {
        console.error('špatná přípona!');
        window.alert('Soubory s touto příponou bohužel nejsou podporovány.');
      }
    } else {
      console.warn('fileAdd není nastaven');
    }
  }

  let fileListRender: Array<object> = [];
  fileList.forEach((single: IUploadFileList, i: number) => {
    fileListRender.push(
      <div className="drop-file-name text-center" key={'file-' + i + id}>
        <div className="drop-file-inner">
          <input
            className="input-ok"
            key={i}
            id={'f-' + i}
            type="text"
            value={single.domain}
            onChange={
              changeName ? (e: any) => changeName(i, e.target.value) : () => {}
            }
            disabled={changeName ? false : true}
          />
          <img
            src={process.env.PUBLIC_URL + '/svgs/status/Ok.svg'}
            alt="done"
          />
        </div>
        <IconButton
          design="transparent"
          icon={IconType.cross}
          onClick={fileRemove ? () => fileRemove(i) : () => {}}
        />
        {/* <button className="btn-tertiary drop-remove" id={"b-"+i} onClick={(fileRemove?()=>fileRemove(i):()=>{})}><img src={process.env.PUBLIC_URL+"/svgs/bold/Trash.svg"} alt="trash" /></button> */}
      </div>
    );
  });

  return (
    <div
      className={
        'card drop-out ' + className + (isDragOver ? ' drag-over-active' : '')
      }
      onDragEnter={() => setIsDragOver(true)}
    >
      <>
        {toggleHelp ? (
          <button onClick={toggleHelp} className="btn-explain">
            !
          </button>
        ) : null}
        {heading ? <h5 className="col-12 text-center">{heading}</h5> : null}
        <div
          onDrop={(e) => drop(e)}
          onDragOver={(e) => e.preventDefault()}
          className="drop-catch-area col-12"
          onDragLeave={() => setIsDragOver(false)}
        ></div>
        <p className="drop-heading text-center no-margin col-12">
          {p ? (
            p
          ) : (
            <>
              Sem přetáhni
              <br />
              nebo nahraj soubory.
            </>
          )}
        </p>
        {fileListRender}
        {multiFileWarning && fileList.length >= multiFileWarningCount ? (
          <>
            <hr />
            {multiFileWarning}
          </>
        ) : null}
        <div className="drop-file-out center text-center col-12">
          {fileList.length > 0 && !allowMultiple ? null : (
            <label
              htmlFor={'drop-file' + id}
              className={classNames(
                stylesBtn.btn,
                stylesBtn.btn__primary,
                'btn button drop-file-label ' +
                  (!allowMultiple && fileList.length > 0 ? 'hidden' : '')
              )}
            >
              Nahrát soubor{allowMultiple ? 'y' : ''}
              <input
                type="file"
                accept={AllowedExtList}
                onChange={(e: any) => handleFileSelect(e.target.files)}
                name={'drop-file' + id}
                id={'drop-file' + id}
                className="drop-file"
                multiple={allowMultiple}
              />
              {isLoading && <Spinner />}
            </label>
          )}
        </div>
        <p className="col-12 drop-filetypes center text-center text-small bold">
          .{allowedExt.join(', .')}
        </p>
      </>
    </div>
  );
}
export default DropUpload;
