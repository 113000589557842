import classNames from 'classnames';
import React, { FC, useState } from 'react';
import { IconType } from '../../enums/IconType';
import IconButton from '../../component/button/IconButton';
import Modal from '../../component/modal/Modal';
import styles from './Menu.module.scss';

export interface MenuItemPlannedProps {
  name: string;
  info: any;
}

export const MenuItemPlanned: FC<MenuItemPlannedProps> = ({ name, info }) => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  return (
    <li className={classNames(styles.MenuItemPlanned)}>
      <span className={styles.MenuItemPlanned_soon}>comming soon</span>
      <span className={styles.MenuItemPlanned_name}>{name}</span>
      <IconButton
        onClick={() => setModalOpen(true)}
        icon={IconType.info}
        design="dark"
      />
      {modalOpen && (
        <Modal
          dismiss={() => setModalOpen(false)}
          heading={name}
          size="small"
          align="center"
        >
          {info}
        </Modal>
      )}
    </li>
  );
};
export default MenuItemPlanned;
